import Textarea from '../ui/Textarea';
import Button from '../ui/Button';
import SendIcon from '../../assets/icons/send.svg?react';
import CloseIcon from '../../assets/icons/close.svg?react';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

export const RepositoryItemHistory = ({
  messages,
  onClose = undefined,
  onSubmittedMessage
}: {
  messages: Message[];
  onClose?: () => void;
  onSubmittedMessage?: (message: string) => void;
}) => {
  return (
    <div
      className={
        'min-w-60 relative px-5 py-4 leading-none flex flex-col rounded-tr-xl rounded-br-xl  w-full h-full'
      }>
      {onClose && (
        <div className="text-right">
          <button type="button" onClick={onClose}>
            <CloseIcon className={'stroke-gray-400 w-4 h-4 -right-3 relative'} />
          </button>
        </div>
      )}
      <Messages messages={messages} />

      {/** Message Input */}
      <MessageInput onSubmittedMessage={onSubmittedMessage} />
    </div>
  );
};

interface FormProps {
  message: string;
}

function MessageInput({ onSubmittedMessage }: { onSubmittedMessage?: (message: string) => void }) {
  const { register, handleSubmit, setValue } = useForm<FormProps>({
    defaultValues: {
      message: ''
    }
  });

  const onSubmit: SubmitHandler<FormProps> = useCallback(
    (data) => {
      if (onSubmittedMessage && data.message && data.message.trim().length > 0) {
        onSubmittedMessage(data.message);
      }
      setValue('message', '');
    },
    [onSubmittedMessage, setValue]
  );

  return (
    <div className={'py-4'}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex gap-3 w-full">
        <Textarea
          placeholder={'Type your message here'}
          rounded
          className={'min-h-12 max-h-40'}
          {...register('message')}
        />
        <Button variant={'primary'} rounded className={'h-10 flex-shrink-0 w-10 p-0 py-1'}>
          <SendIcon className={'stroke-white'} />
        </Button>
      </form>
    </div>
  );
}

function MessageSpacer({ text }: { text: string }) {
  const beforeClasses =
    'relative before:content-[""] before:block before:h-0.5 before:absolute before:w-full before:bg-gray-200 before:top-1/2 before:z-0 before:transform before:-translate-y-1/2';

  return (
    <div className={`${beforeClasses} flex justify-center py-4`}>
      <span className={` bg-gray-25 px-2 relative z-10 text-gray text-sm`}>{text}</span>
    </div>
  );
}

export interface Message {
  sender: 'user' | 'other' | 'system';
  message: string;
  sentAt: string;
  senderName?: string | null;
}

function Messages({ messages }: { messages: Message[] }) {
  const [messagesByDayGroups, setMessagesByDayGroups] = useState<
    { date: string; messages: Message[] }[]
  >([]);

  useEffect(() => {
    setMessagesByDayGroups(
      messages.reduce(
        (acc, message) => {
          const lastMessage = acc[acc.length - 1];
          const lastMessageDate = new Date(lastMessage?.date);
          const currentMessageDate = new Date(message.sentAt);
          const isSameDay = lastMessageDate.toDateString() === currentMessageDate.toDateString();

          if (lastMessage && isSameDay) {
            lastMessage.messages.push(message);
          } else {
            acc.push({
              date: message.sentAt,
              messages: [message]
            });
          }
          return acc;
        },
        [] as { date: string; messages: Message[] }[]
      )
    );

    setTimeout(() => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
      }
    }, 50);
  }, [messages]);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  // Group messages by date

  function formatDateToRelativeOrAbsolute(date: string) {
    const today = new Date();
    const messageDate = new Date(date);
    if (today.toDateString() === messageDate.toDateString()) {
      return 'Today';
    }

    const yesterday = today.getDate() - 1;
    const yesterdayDate = new Date(today.setDate(yesterday));
    if (yesterdayDate.toDateString() === messageDate.toDateString()) {
      return 'Yesterday';
    }

    return messageDate.toLocaleDateString();
  }

  /** Message Container */

  return (
    <div ref={messageContainerRef} className={'overflow-auto flex-1 w-full grow'}>
      {/** Messages Track */}
      <div className={'flex gap-4 flex-col '}>
        {/** Message */}
        {messagesByDayGroups.map((group) => {
          return (
            <div key={group.date} className={'flex gap-4 flex-col'}>
              <MessageSpacer text={formatDateToRelativeOrAbsolute(group.date)} />
              {group.messages.map((message, index) => (
                <MessageItem key={'message-' + index} message={message} />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const MessageItem = ({ message }: { message: Message }) => {
  const variant = message.sender;
  const colorScheme = messageColorSchemeMap[variant];
  const roundedClass = 'rounded-lg';

  const userClass = 'rounded-br-none';
  const otherClass = 'rounded-tl-none';

  const isUser = variant === 'user';

  const classVariant = isUser ? userClass : otherClass;
  const wrapperClass = isUser ? 'justify-end' : 'justify-start';
  const senderLabel = isUser
    ? 'You'
    : variant === 'system'
      ? `SYSTEM ${message.senderName ? `(${message.senderName})` : ''}`
      : message.senderName;

  function formatHumanReadableWeekdayAndHour(sentAt: string) {
    const date = new Date(sentAt);
    const weekday = date.toLocaleDateString('en-US', { weekday: 'long' });
    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    return `${weekday} ${time.toLowerCase().replace(' ', '')}`;
  }

  return (
    <div className={`flex ${wrapperClass}`}>
      <div className={'w-11/12'}>
        <div className="flex justify-between items-center mb-1">
          <div className={'text-sm'}>{senderLabel}</div>
          <div className={'text-gray text-xs'}>
            {formatHumanReadableWeekdayAndHour(message.sentAt)}
          </div>
        </div>
        <div
          className={`min-h-16 px-3.5 py-2.5 text-base ${colorScheme.backgroundColor} ${colorScheme.textColor} ${roundedClass} ${classVariant} break-words`}>
          {message.message}
        </div>
      </div>
    </div>
  );
};

interface MessageColorScheme {
  backgroundColor: string;
  textColor: string;
  iconColor: string;
}

const messageColorSchemeMap: Record<'user' | 'other' | 'system', MessageColorScheme> = {
  user: {
    backgroundColor: 'bg-primary-600',
    textColor: 'text-white',
    iconColor: 'stroke-primary-800'
  },
  other: {
    backgroundColor: 'bg-gray-200',
    textColor: 'text-gray-900',
    iconColor: 'stroke-gray-300'
  },
  system: {
    backgroundColor: 'bg-success-10',
    textColor: 'text-success-900',
    iconColor: 'stroke-success-800'
  }
};
