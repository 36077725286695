import Badge from '../../components/ui/Badge';
import FileTypeIcon from '../repository/FileTypeIcon';
import ProgressBar from '../ui/ProgressBar';
import { AssetStatus, AssetStatusLabels, AssetType } from '../../features/repository/types';
import { getLanguageDisplayName } from '../../common/languages';
export interface TreeViewNodeAssetTemplateProps {
  filename: string;
  location: string;
  language: string;
  message?: string;
  status?: AssetStatus;
  progress?: number;
  type: AssetType;
}

const TreeViewNodeAssetTemplate = ({
  asset,
  showBadge = true
}: {
  asset?: TreeViewNodeAssetTemplateProps;
  showBadge?: boolean;
}) => {
  if (!asset) return null;
  return (
    <div className={'flex gap-3'}>
      <div>
        <FileTypeIcon extension={asset.filename.split('.').pop()?.toLowerCase() ?? ''} />
      </div>
      <div className={'w-full'}>
        <div className="text-sm">
          <div className="text-gray-700 font-medium">{asset.filename}</div>
          <div className="text-gray-600">{getLanguageDisplayName(asset.language)}</div>
          {asset.message && <div className="text-gray-700 font-medium">{asset.message}</div>}
        </div>
        {asset.progress && <ProgressBar value={asset.progress} />}
      </div>

      {asset.status && showBadge && (
        <div className={'absolute top-3.5 right-14'}>
          <AssetStatusBadge status={asset.status} />
        </div>
      )}
    </div>
  );
};

const AssetStatusBadge = ({ status }: { status: AssetStatus }) => {
  return <Badge text={AssetStatusLabels[status]} icon="dot" color={getStatusBadgeColor(status)} />;
};
const getStatusBadgeColor = (status: AssetStatus) => {
  switch (status) {
    case 'NEW':
    case 'TO_CONFORM':
      return 'warning';
    case 'ACCEPTED':
      return 'success';
    case 'TO_DELETE':
    case 'DELETED':
    case 'MISSING':
      return 'error';
    default:
      return 'warning';
  }
};

export default TreeViewNodeAssetTemplate;
