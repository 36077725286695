import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Header from '../../components/shared/Header';
import MetadataRichSearchPage from './MetadataRichSearchPage';
import QualityControlPage from './QualityControlPage';
import MetadataRichDetailPage from './MetadataRichDetailPage';

export default function RepositoryRouter() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<MetadataRichSearchPage />} />
        <Route path=":titleId" element={<MetadataRichDetailPage />} />
        <Route path=":titleId/quality-control" element={<QualityControlPage />} />
        <Route path="*" element={<Navigate to="/repository" replace />} />
      </Routes>
    </>
  );
}
