import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REPOSITORY_API_URL } from '../../common/constants';
import { getAccessToken } from '../../common/auth';
import * as queries from '../../graphql/queries/castlabs';
import { CastlabsProcessStatus } from './types';

export const castlabsRepositoryApi = createApi({
  reducerPath: 'castlabsRepositoryApi',
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: REPOSITORY_API_URL,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      if (accessToken) headers.set('authorization', accessToken);
      headers.set('x-castlabs-organization', 'above');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getProcessQuery: builder.query<CastlabsProcessStatus, { id: string }>({
      query: (variables) => ({
        url: '',
        method: 'POST',
        body: JSON.stringify({ query: queries.getProcess, variables: variables })
      }),
      transformResponse: (response: { data: { process: CastlabsProcessStatus } }) => {
        return response?.data?.process;
      }
    }),

    getProcess: builder.mutation<CastlabsProcessStatus, { id: string }>({
      query: (variables) => ({
        url: '',
        method: 'POST',
        body: JSON.stringify({ query: queries.getProcess, variables: variables })
      }),
      transformResponse: (response: { data: { process: CastlabsProcessStatus } }) => {
        return response?.data?.process;
      }
    }),
    previewGenerate: builder.mutation<
      CastlabsProcessStatus,
      {
        folder_id: string;
      }
    >({
      query: (variables) => ({
        url: '',
        method: 'POST',
        body: JSON.stringify({
          query: queries.previewGenerate,
          variables: variables
        })
      }),
      transformResponse: (response: { data: { previewGenerate: CastlabsProcessStatus } }) => {
        return response?.data?.previewGenerate;
      }
    }),
    generatePreviewToken: builder.mutation<
      { drmtoday_token: string; asset_id: string; dash_url: string },
      {
        asset_id: string;
      }
    >({
      query: (variables) => ({
        url: '',
        method: 'POST',
        body: JSON.stringify({ query: queries.previewGenerateToken, variables: variables })
      }),
      transformResponse: (response: {
        data: {
          previewGenerateToken: {
            drmtoday_token: string;
            asset_id: string;
            dash_url: string;
          };
        };
      }) => {
        return response?.data?.previewGenerateToken;
      }
    })
  })
});

export const {
  useGetProcessQueryQuery,
  useGetProcessMutation,
  useGeneratePreviewTokenMutation,
  usePreviewGenerateMutation
} = castlabsRepositoryApi;
