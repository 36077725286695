import { Helmet } from 'react-helmet-async';
import React, { ReactElement, useEffect, useState } from 'react';
import RepositorySearch from '../../components/repository/RepositorySearch';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Skeleton from '../../components/ui/Skeleton';
import Table from '../../components/ui/Table';
import SearchIcon from '../../assets/icons/search.svg?react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  ListRepositoryItemsRequest,
  RepositoryItem,
  RepositoryItemQcStatus,
  RepositoryItemQcStatusLabels,
  RepositoryItemType
} from '../../features/repository/types';
import { setItems } from '../../features/repository/repositoryItemsSlice';
import { getRepositoryItems } from '../../features/repository/repositoryApi';
import MovieIcon from '../../assets/icons/movie.svg?react';
import SeriesIcon from '../../assets/icons/series.svg?react';
import EpisodeIcon from '../../assets/icons/episode.svg?react';
import ShortIcon from '../../assets/icons/short.svg?react';
import SpecialIcon from '../../assets/icons/special.svg?react';
import CheckDone from '../../assets/icons/check-done.svg?react';
import DocumentaryIcon from '../../assets/icons/documentary.svg?react';
import Badge from '../../components/ui/Badge';
import { TitleLabel } from '../../components/shared/TitleLabel';

const DEFAULT_DISTRIBUTOR = 'WARNER_BROS';

export default function MetadataRichSearchPage() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { results, meta } = useSelector((state: RootState) => state.repositoryItems);

  const queryParams = Object.fromEntries(searchParams.entries());
  const cleanedQueryParams = (): ListRepositoryItemsRequest => {
    const params = Object.fromEntries(
      Object.entries(queryParams).filter(
        ([key, value]) => value !== '' && value && (key === 'page' ? parseInt(value) == 1 : true)
      )
    );

    if (params.parentId) {
      delete params.search;
    }
    return params as ListRepositoryItemsRequest;
  };

  const onSearch = (newValues: Record<string, ListRepositoryItemsRequest>) => {
    setSearchParams(
      new URLSearchParams(
        Object.entries(newValues).map(([key, value]) => value && [key, value.toString()])
      )
    );
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const result = await getRepositoryItems(cleanedQueryParams());
      dispatch(setItems(result));
    } catch (error) {
      console.error(error);
      dispatch(setItems({ results: [], meta: null }));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!queryParams.distributor) {
      setSearchParams({ distributor: DEFAULT_DISTRIBUTOR });
    } else {
      getData();
    }
  }, [searchParams]);

  const iconsMap: Record<RepositoryItemType, ReactElement> = {
    MOVIE: <MovieIcon className="stroke-gray-300" />,
    EPISODE: <EpisodeIcon className="stroke-gray-300" />,
    SHORT: <ShortIcon className="stroke-gray-300" />,
    STANDALONE: <SpecialIcon className="fill-gray-300" />,
    DOCUMENTARY: <DocumentaryIcon className="fill-gray-300" />,
    SERIES: <SeriesIcon className="stroke-gray-300" />
  };

  function renderContentTitleColumn(title: RepositoryItem): ReactElement {
    function renderContentTitle(title: RepositoryItem): ReactElement {
      return (
        <TitleLabel
          repositoryItem={title}
          seriesOnClick={() => {
            setSearchParams({
              distributor: queryParams.distributor || '',
              repository: title.repository,
              qcStatus: queryParams.qcStatus || '',
              parentId: title.seriesId || '',
              search: `series: "${title.seriesTitle}"`
            });
          }}
          seasonOnClick={() => {
            setSearchParams({
              distributor: queryParams.distributor || '',
              repository: title.repository,
              qcStatus: queryParams.qcStatus || '',
              parentId: title.seriesId || '',
              seasonNumber: title.seasonNumber?.toString() || '',
              search: `series: "${title.seriesTitle}" season:${title.seasonNumber}`
            });
          }}
          titleOnClick={() => navigator(`${title._id}`)}
        />
      );
    }
    return (
      <div className="flex gap-3 items-center">
        <div>{iconsMap[title.itemType]}</div>
        <div>
          <div className="text-primary-600 font-medium">{renderContentTitle(title)}</div>
          <div className="text-gray-600 flex gap-2">
            {title.eidrId?.length > 0 && <span>EIDR: {title.eidrId}</span>}
            {title.catalogId && title.catalogId?.length > 0 && (
              <span>
                {title.catalogIdType}: {title.catalogId}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderQCStatusBadges(qcStatus: RepositoryItemQcStatus[] | null) {
    return qcStatus?.map((status, index) => {
      return <Badge key={index} text={RepositoryItemQcStatusLabels[status]} icon={'dot'} />;
    });
  }

  const columns = [
    {
      key: 'id',
      title: 'Title',
      className: 'w-auto',
      render: (title: RepositoryItem) => renderContentTitleColumn(title)
    },
    {
      key: 'edit_version',
      title: 'Edit Version',
      className: 'w-36',
      render: (title: RepositoryItem) =>
        title.itemType != 'SERIES' && <span className="text-gray-600">{title.editVersion}</span>
    },
    {
      key: 'qc_status',
      title: 'ToDO',
      className: 'w-36',
      render: (title: RepositoryItem) =>
        title.itemType != 'SERIES' && (
          <div className="flex flex-col gap-1.5">{renderQCStatusBadges(title.qcStatus)}</div>
        )
    },
    {
      key: '_id',
      title: '',
      className: 'w-20 text-center',
      render: (title: RepositoryItem) =>
        title.itemType != 'SERIES' && (
          <div className="float-right ">
            <Link
              to={`${title._id}/quality-control`}
              className="border rounded py-1.5 px-1.5 flex items-center justify-center border-gray-300  hover:bg-gray-200 transition-all">
              <CheckDone />
            </Link>
          </div>
        )
    }
  ];

  return (
    <>
      <Helmet>
        <title>Above Media | Repository</title>
      </Helmet>
      <RepositorySearch {...cleanedQueryParams()} onSearch={onSearch} />
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl overflow-hidden mb-8">
        <div className="p-5 flex flex-row gap-4 items-center justify-center h-24 border-b border-gray-200">
          <div className="grow gap-2 flex flex-row items-center font-semibold text-lg text-gray-900">
            Distributor Titles
          </div>
        </div>

        <div className="relative">
          {isLoading && (
            <>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50"></div>
              <div className="border-t border-gray-100">
                <Skeleton />
              </div>
            </>
          )}

          {!isLoading && (
            <Table
              data={results || []}
              columns={columns}
              rowId="_id"
              disabledRows={[]}
              pagination={{
                totalItems: meta?.total || 0,
                itemsPerPage: meta?.pageSize || 30,
                currentPage: meta?.page || 1,
                onPageChange: (page) => setSearchParams({ ...queryParams, page: page.toString() })
              }}
              noDataIcon={<SearchIcon className="w-8 h-8 stroke-gray-400" />}
              noDataLabel="No titles were found!"
              noDataDescription="Your search did not match any title. Please try again later."
            />
          )}
        </div>
      </div>
    </>
  );
}
