import React, { useState } from 'react';
import { clsx } from 'clsx';
import Button from './Button';
import ChevronDownIcon from '../../assets/icons/chevron-down.svg?react';
import ChevronUpIcon from '../../assets/icons/chevron-up.svg?react';
import PlusSquareIcon from '../../assets/icons/plus-square.svg?react';

/*

How to use it:
-- Replace 'any' with the node's value type and 'string' with the node's ID type
const TreeData: TreeViewNode<any, string>[] = [
  {
    id: '8',
    value: {
      filename: 'Westworld_S1E1_TH_ENG.mp4',
      language: 'English',
      message: 'Rejected since this file contains multiple video tracks',
      status: 'Rejected'
    },
    children: [
      {
        id: '7',
        value: {
          filename: 'Westworld_S1E2_TH_ENG.mp4',
          language: 'English',
          message: 'Rejected since this file contains multiple video tracks',
          status: 'Rejected'
        },
        children: [
          {
            id: '1',
            value: {
              filename: 'Westworld_S1E3_TH_ENG.mp4',
              language: 'English',
              message: 'Rejected since this file contains multiple video tracks',
              status: 'Rejected'
            }
          }
        ]
      },
      {
        id: '4',
        value: {
          filename: 'Westworld_S1E4_TH_ENG.mp4',
          language: 'English',
          message: 'Rejected since this file contains multiple video tracks',
          status: 'Rejected'
        }
      },
      {
        id: '5',
        value: {
          filename: 'Westworld_S1E5_TH_ENG.mp4',
          language: 'English',
          message: 'Rejected since this file contains multiple video tracks',
          status: 'Rejected'
        }
      }
    ]
  },
  {
    id: '6',
    value: {
      filename: 'Westworld_S1E6_TH_ENG.mp4',
      language: 'English',
      message: 'Rejected since this file contains multiple video tracks',
      status: 'Rejected'
    }
  }
];
const [selectedNode, setSelectedNode] = useState<string | null>(null);

<TreeView
  data={TreeData}
  render={(value) => (
    <div>
      <div>{value.filename}</div>
      <div>{value.language}</div>
      <div>{value.message}</div>
      <div>{value.status}</div>
    </div>
  )}
/>
*/

export interface TreeViewNode<T, U> {
  id: U;
  value: T;
  children?: TreeViewNode<T, U>[];
}

export interface TreeViewProps<T, U> {
  className?: string;
  data: TreeViewNode<T, U>[];
  render: (value: T) => React.ReactNode;
  selected?: U | null;
  setSelected?: (id: U) => void;
  onSelectBehaviour?: 'collapse' | 'none';
}

// Find a parent node in the tree
export const findTreeViewParentNode = <T, U>(
  nodes: TreeViewNode<T, U>[],
  id: U
): TreeViewNode<T, U> | null => {
  for (const node of nodes) {
    if (node.children) {
      if (node.children.some((child) => child.id === id)) {
        return node;
      }
      const found = findTreeViewParentNode(node.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

// Find a node in the tree
export const findTreeViewNode = <T, U>(
  nodes: TreeViewNode<T, U>[],
  id: U
): TreeViewNode<T, U> | null => {
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    }
    if (node.children) {
      const found = findTreeViewNode(node.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

// TreeNode Component
const TreeView = <T, U>({
  className,
  data,
  render,
  selected,
  setSelected,
  onSelectBehaviour
}: TreeViewProps<T, U>) => {
  // Generate random id for the tree view
  const treeViewId = Math.random().toString(36).substring(7);
  const [expandedNodes, setExpandedNodes] = useState<Set<U>>(new Set());

  function handleNodeClick(id: U) {
    if (selected === id) return;
    setSelected && setSelected(id);
    if (onSelectBehaviour === 'collapse') {
      setExpandedNodes(new Set());
    }
  }

  const renderTreeView = (
    nodes: TreeViewNode<T, U>[],
    isChild: boolean = false,
    parentExpanded = true,
    setParentExpand = () => {}
  ) => {
    function recursiveGetAllChildIds(node: TreeViewNode<T, U>): U[] {
      return [
        node.id,
        ...(node.children?.flatMap((child: TreeViewNode<T, U>) => recursiveGetAllChildIds(child)) ||
          [])
      ];
    }
    const toggleNode = (id: U, childIds: U[]) => {
      setExpandedNodes((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(id)) {
          newSet.delete(id);
          // Remove children node ids
          childIds?.forEach((childId) => {
            newSet.delete(childId);
          });
        } else {
          newSet.add(id);
        }
        return newSet;
      });
    };
    function recursiveCheckIfContainsAnyChildSelected(node: TreeViewNode<T, U>): boolean {
      return (
        selected === node.id ||
        node.children?.some((child: TreeViewNode<T, U>) =>
          recursiveCheckIfContainsAnyChildSelected(child)
        ) ||
        false
      );
    }

    return nodes.map((node) => {
      const isExpanded = expandedNodes.has(node.id);
      const isSelected = selected === node.id;
      const isAnyChildSelectedRecursive = recursiveCheckIfContainsAnyChildSelected(node);
      const allChildIds = recursiveGetAllChildIds(node);

      if (!parentExpanded && !isAnyChildSelectedRecursive && !isSelected) {
        return null;
      }

      return (
        <div
          key={`${treeViewId}-${node.id}`}
          className={clsx('relative', {
            'ml-20 mt-4': isChild,
            'my-8': !isChild,
            'z-10': isAnyChildSelectedRecursive,
            'z-1': !isAnyChildSelectedRecursive
          })}>
          {!parentExpanded && isAnyChildSelectedRecursive && (
            <div className={'relative cursor-pointer max-w-max'} onClick={setParentExpand}>
              <div
                className={
                  'absolute bg-gray-250 top-1/2 transform -translate-y-1/2  w-10 h-px -left-10'
                }></div>
              <div
                className={
                  'absolute -left-[3.1rem] top-1/2 transform -translate-y-1/2 z-30 bg-white'
                }>
                <PlusSquareIcon className={'w-6 h-6 stroke-primary'} />
              </div>
              <div className="my-3.5 ml-4">More ({nodes.length - 1} files)...</div>
            </div>
          )}
          <div
            className={clsx('border rounded-xl p-4 w-full relative cursor-pointer', {
              'bg-gray-50 border-gray-250 ': isSelected,
              'bg-white border-gray-200 ': !isSelected,
              'z-10': isAnyChildSelectedRecursive,
              'z-1': !isAnyChildSelectedRecursive
            })}
            onClick={() => handleNodeClick(node.id)}
            onDoubleClick={() => toggleNode(node.id, allChildIds)}>
            {render(node.value)}
            {node.children && node.children.length > 0 && (
              <div>
                <Button
                  size={'sm'}
                  className={
                    'absolute top-2 right-3.5 p-2 bg-none! border-none rounded-full hover:bg-none!'
                  }
                  onClick={(evt) => {
                    evt.stopPropagation();
                    toggleNode(node.id, allChildIds);
                  }}>
                  {isExpanded ? (
                    <ChevronUpIcon className={'stroke-gray-600'} />
                  ) : (
                    <ChevronDownIcon className={'stroke-gray-600'} />
                  )}
                </Button>
              </div>
            )}
            {isChild && (
              <span
                className={clsx(
                  'absolute w-10 top-1/2 h-[9999px] -translate-y-full border-l border-b  rounded-bl-xl -left-10 ',
                  {
                    'border-gray-200 ': !isSelected,
                    'border-primary': isSelected || isAnyChildSelectedRecursive
                  }
                )}></span>
            )}
          </div>

          {node.children &&
            node.children.length > 0 &&
            (isExpanded || isAnyChildSelectedRecursive) && (
              <div className="overflow-hidden">
                {renderTreeView(node.children, true, isExpanded, () =>
                  toggleNode(node.id, allChildIds)
                )}
              </div>
            )}
        </div>
      );
    });
  };

  return <div className={className}>{renderTreeView(data)}</div>;
};

export default TreeView;
