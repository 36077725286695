import { Meta } from '../../common/types';
import {
  AddAssetQCReportOutput,
  Asset,
  AssetStatus,
  GetRepositoryItemAndAssetsResponse,
  GetRepositoryItemAssetsResponse,
  ListRepositoryItemsRequest,
  ListRepositoryItemsResult,
  ListRepositoryItemsResultsItem,
  RepositoryItem,
  RepositoryItemDetails,
  RepositoryItemQcStatus,
  AssetQCReportInput
} from './types';
import { generateClient } from 'aws-amplify/api';
import * as queries from '../../graphql/queries/repositories';
import * as mutations from '../../graphql/mutations/repositories';
import { GraphQLResult } from '@aws-amplify/api';
import { print } from 'graphql';

const client = generateClient();

export const getRepositoryItems = async (
  variables: ListRepositoryItemsRequest
): Promise<{ results: RepositoryItem[]; meta: Meta | null } | undefined> => {
  const result = (await client.graphql({
    query: print(queries.listRepositoryItems),
    variables: {
      search: variables.search,
      distributor: variables.distributor,
      repository: variables.repository,
      qcStatus: variables.qcStatus,
      page: variables.page,
      parentId: variables.parentId,
      seasonNumber: variables.seasonNumber,
      pageSize: variables.pageSize
    }
  })) as GraphQLResult<ListRepositoryItemsResult>;

  function mapRepositoryItem(data: ListRepositoryItemsResultsItem): RepositoryItem {
    const qcStatus = [] as RepositoryItemQcStatus[];
    if (data.hasAssetToQc) qcStatus.push('TO_QC');
    if (data.hasAssetToConform) qcStatus.push('TO_CONFORM');

    return {
      _id: data._id,
      itemType: data.itemType,
      title: data.title,
      eidrId: data.eidrId,
      catalogId: data.titleCatalogId,
      catalogIdType: data.catalogIdType,
      repository: data.repository,
      editVersion: data.editVersion,
      seriesId: data.series?._id,
      seriesTitle: data.series?.title,
      seriesEidrId: data.series?.eidrId,
      seriesCatalogId: data.series?.titleCatalogId,
      seriesCatalogIdType: data.series?.catalogIdType,
      seasonNumber: data.seasonNumber,
      episodeNumber: data.episodeNumber,
      releaseDate: data.releaseDate,
      modifiedAt: data.modifiedAt,
      createdAt: data.createdAt,
      qcStatus: qcStatus
    };
  }

  return {
    results: result.data?.listRepositoryItems?.results.map(mapRepositoryItem),
    meta: result.data?.listRepositoryItems?.meta
  };
};

export const getRepositoryItem = async (titleId: string): Promise<RepositoryItemDetails> => {
  const result = (await client.graphql({
    query: print(queries.getRepositoryItemAndAssets),
    variables: {
      _id: titleId
    }
  })) as GraphQLResult<GetRepositoryItemAndAssetsResponse>;

  result.data.getRepositoryItemById.assets = [
    ...result.data.listAssets.map((asset) => {
      const created = asset.history.find((h) => h.action === 'CREATED');
      asset.createdAt = created?.timestamp;
      asset.createdBy = created?.user;
      return asset;
    })
  ];

  function formatAssetHistoryMessages(asset: Asset) {
    const assetName = asset.location.split('/').pop();

    return asset.history
      .map((history) => {
        if (history.action === 'CREATED') {
          return {
            ...history,
            message: `Asset creation ${assetName}`
          };
        }
        if (history.action === 'STATUS_CHANGED') {
          return {
            ...history,
            message: history.message.replace('Asset status', `Asset ${assetName} status`)
          };
        }
        return null;
      })
      .filter((history) => history !== null);
  }

  // Concatenate assets history:
  result.data.getRepositoryItemById.history = [
    ...result.data.getRepositoryItemById.history,
    ...result.data.listAssets.map((asset) => formatAssetHistoryMessages(asset)).flat()
  ] // Sort all messages by timestamp
    .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

  return result.data.getRepositoryItemById;
};

export const getRepositoryItemAssets = async (titleId: string): Promise<Asset[]> => {
  const result = (await client.graphql({
    query: print(queries.getRepositoryItemAssets),
    variables: {
      _id: titleId
    }
  })) as GraphQLResult<GetRepositoryItemAssetsResponse>;

  return result.data.listAssets;
};

export const addAssetQcReport = async (
  assetId: string,
  report: AssetQCReportInput,
  force = false
): Promise<boolean> => {
  const res = (await client.graphql({
    query: print(mutations.addAssetQcReport),
    variables: {
      _id: assetId,
      report: report,
      force: force
    }
  })) as GraphQLResult<AddAssetQCReportOutput>;

  return res.data?.added === true;
};

export const updateAssetStatus = async (assetId: string, status: AssetStatus): Promise<Asset> => {
  const res = (await client.graphql({
    query: print(mutations.updateAssetStatus),
    variables: {
      _id: assetId,
      status: status
    }
  })) as GraphQLResult<Asset>;

  return res.data;
};

export const updateAssetComment = async (assetId: string, comment: string): Promise<Asset> => {
  const res = (await client.graphql({
    query: print(mutations.updateAssetComment),
    variables: {
      _id: assetId,
      comment: comment
    }
  })) as GraphQLResult<Asset>;

  return res.data;
};
