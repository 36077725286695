import React, { ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import Button from '../ui/Button';
import { VUE_APP_HOST } from '../../common/constants';
import Logo from '../../assets/above-logo.svg?react';
import AvatarFilledIcon from '../../assets/icons/avatar-filled.svg?react';
import LogoutIcon from '../../assets/icons/logout.svg?react';
import SettingsIcon from '../../assets/icons/settings.svg?react';
import { Tooltip } from '../ui/Tooltip';
import { useAppSelector } from '../../app/hooks';
import { selectUserGroups } from '../../features/userGroups/userGroupsSlice';
import PushSubscription from '../webpush/PushSubscription';
import { useUserContext } from '../../common/providers/UserProvider';
import { lightSignOut } from '../../common/auth';
import StudioLogo from './StudioLogo';
import ChevronDownIcon from '../../assets/icons/chevron-down.svg?react';
import Input from '../ui/Input';
import { Popover, PopoverAnchor, PopoverContent } from '../ui/Popover';

const MenuItem = ({
  active,
  ...props
}: {
  active?: boolean;
  to: string;
  badge?: string;
  onClick?: () => void;
  children?: ReactNode;
}) => {
  const menuItemClasses = clsx(
    'transition text-primary-700 py-2 px-3 rounded-lg cursor-pointer block',
    {
      'bg-primary text-white': active,
      'hover:bg-primary-50 hover:text-primary-700 font-normal': !active,
      'mr-2': props.badge
    }
  );
  return (
    <li>
      <Link className={menuItemClasses} to={props.to}>
        {props.children}
        {props.badge && (
          <span className="relative -left-5">
            <span className="bg-success-500 text-white text-xs px-1 rounded-lg ml-1 absolute -top-4">
              {props.badge}
            </span>
          </span>
        )}
      </Link>
    </li>
  );
};

const MenuItemMegaMenu = ({
  active,
  label,
  menuItems,
  ...props
}: {
  active?: boolean;
  label: string;
  menuItems: {
    label: string;
    link: string;
    pathname: string;
    icon: ReactNode;
  }[];
  badge?: string;
}) => {
  const [search, setSearch] = React.useState('');
  const [expanded, setExpanded] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState(menuItems);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const triggerRef = React.useRef<HTMLLIElement>(null);
  const popupRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (search) {
      setSearchResults(
        menuItems.filter((k) => k.label.toLowerCase().includes(search.toLowerCase()))
      );
    } else {
      setSearchResults(menuItems);
    }
  }, [search]);

  const handleClickOutside = (e: MouseEvent) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(e.target as Node) &&
      triggerRef.current &&
      !triggerRef.current.contains(e.target as Node)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    if (expanded) {
      inputRef.current?.focus();
      document.addEventListener('click', handleClickOutside);
    } else {
      setSearch('');
      document.removeEventListener('click', handleClickOutside);
    }
  }, [expanded]);

  const menuItemClasses = clsx(
    'transition text-primary-700 py-2 px-3 rounded-lg cursor-pointer block',
    {
      'bg-primary text-white': active,
      'hover:bg-primary-50 hover:text-primary-700 font-normal': !active,
      'mr-2': props.badge
    }
  );
  function isSubItemActive(item: {
    label: string;
    link: string;
    pathname: string;
    icon: React.ReactNode;
  }) {
    // Check if link has query params
    const linkQueryParamsPart = item.link.split('?')[1];
    const urlParams = new URLSearchParams(linkQueryParamsPart);

    if (urlParams.size) {
      // get current location params
      const currentUrlParams = new URLSearchParams(location.search);
      // check if all params from item link are in current location
      let found = true;
      urlParams.forEach((value, key) => {
        if (currentUrlParams.get(key) !== value) {
          found = false;
        }
      });

      return found;
    }
    return item.link == location.pathname + location.search;
  }
  return (
    <li className={'relative z-10'} ref={triggerRef}>
      <Popover open={expanded}>
        <PopoverAnchor>
          <div className={menuItemClasses} onClick={() => setExpanded(!expanded)}>
            <div className="flex relative">
              <div className="flex items-center gap-1">
                {label}
                <ChevronDownIcon
                  className={`${active ? 'stroke-white' : 'stroke-gray-600'} h-5 mt-0.5`}
                />
              </div>

              {props.badge && (
                <span className="relative -left-5">
                  <span className="bg-success-500 text-white text-xs px-1 rounded-lg ml-1 absolute -top-4">
                    {props.badge}
                  </span>
                </span>
              )}
            </div>
          </div>
        </PopoverAnchor>
        <PopoverContent
          showArrow={false}
          className={'bg-white p-0 w-max   border-gray-200   rounded-md border place-items-center'}>
          <div ref={popupRef} className={'p-5'}>
            <div className={'w-full mb-3'}>
              <Input
                ref={inputRef}
                rounded
                placeholder={'Search...'}
                inputSize={'sm'}
                onChange={(val) => setSearch(val.target.value)}
                value={search}
              />
            </div>
            {searchResults.length == 0 && (
              <div className={'text-gray-900 text-md mt-5'}>No studio was found</div>
            )}
            {searchResults.length > 0 && (
              <div
                className={`w-full grid gap-2 ${searchResults.length == 0 ? 'hidden' : searchResults.length == 1 ? 'grid-cols-1' : searchResults.length == 2 ? 'grid-cols-2' : 'grid-cols-3'}`}>
                {searchResults.map((item) => {
                  const menuSubItemClasses = clsx(
                    ' w-full flex flex-col items-center justify-center  py-5 px-3 rounded-lg cursor-pointer hover:bg-primary-50 hover:text-primary-700 font-normal text-center items-center',
                    {
                      'bg-primary-50 text-primary-700 font-normal': isSubItemActive(item)
                    }
                  );
                  return (
                    <Link
                      key={item.link}
                      to={item.link}
                      className={menuSubItemClasses}
                      onClick={() => setExpanded(false)}>
                      <div className={'h-12 flex justify-center items-center'}>{item.icon}</div>
                      <div className={'text-gray-900 text-md text-center w-max mt-2'}>
                        {item.label}
                      </div>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </li>
  );
};

const menuItems: {
  label: string;
  link?: string;
  pathname?: string;
  userGroups: string[];
  deprecated: boolean;
  badge?: string;
  subItems?: {
    label: string;
    link: string;
    pathname: string;
    icon: ReactNode;
  }[];
}[] = [
  {
    label: 'Catalog',
    link: `/catalog`,
    pathname: '/catalog',
    userGroups: ['above_admin', 'above_ops', 'csp_user', 'studio_admin'],
    deprecated: false,
    badge: 'new'
  },
  {
    label: 'Orders',
    link: `/orders`,
    pathname: '/orders',
    userGroups: ['above_admin', 'above_ops', 'studio_admin', 'csp_user'],
    deprecated: false,
    badge: 'new'
  },
  {
    label: 'Ingestion',
    link: `/ingestion`,
    pathname: '/ingestion',
    userGroups: ['basic'],
    deprecated: false
  },
  {
    label: 'Repository',
    userGroups: ['above_admin'],
    deprecated: false,
    badge: 'new',
    subItems: [
      {
        label: 'HBO',
        link: '/repository?distributor=HBO',
        pathname: '/repository',
        icon: <StudioLogo studio={'HBO'} height={'1.5rem'} />
      },
      {
        label: 'Warner',
        link: '/repository?distributor=WARNER_BROS',
        pathname: '/repository',
        icon: <StudioLogo studio={'WARNER_BROS'} height={'2.25rem'} />
      },
      {
        label: 'Disney',
        link: '/repository?distributor=DISNEY',
        pathname: '/repository',
        icon: <StudioLogo studio={'DISNEY'} height={'2rem'} />
      },
      {
        label: 'Adult Swim',
        link: '/repository?distributor=ADULT_SWIM',
        pathname: '/repository',
        icon: <StudioLogo studio={'ADULT_SWIM'} height={'1.75rem'} />
      },
      {
        label: 'Sony',
        link: '/repository?distributor=SONY',
        pathname: '/repository',
        icon: <StudioLogo studio={'SONY'} height={'1rem'} />
      },
      {
        label: 'Turner',
        link: '/repository?distributor=TURNER',
        pathname: '/repository',
        icon: <StudioLogo studio={'TURNER'} height={'1.5rem'} />
      },
      {
        label: 'Universal',
        link: '/repository?distributor=UNIVERSAL',
        pathname: '/repository',
        icon: <StudioLogo studio={'UNIVERSAL'} height={'2.25rem'} />
      },
      {
        label: 'Cartoon Network',
        link: '/repository?distributor=CARTOON_NETWORK',
        pathname: '/repository',
        icon: <StudioLogo studio={'CARTOON_NETWORK'} height={'2.25rem'} />
      }
    ]
  },
  {
    label: 'Title Cards',
    link: `/title-cards`,
    pathname: '/title-cards',
    userGroups: ['above_admin', 'above_ops'],
    deprecated: false
  },
  {
    label: 'Catalog',
    link: `${VUE_APP_HOST}/catalog`,
    pathname: '/catalog',
    userGroups: ['basic'],
    deprecated: true
  },
  {
    label: 'Purchase Orders',
    link: `${VUE_APP_HOST}/orders`,
    pathname: '/orders',
    userGroups: ['basic'],
    deprecated: true
  },
  {
    label: 'Orders Management',
    link: `${VUE_APP_HOST}/orders/orders-titles`,
    pathname: '/orders/orders-titles',
    userGroups: ['basic'],
    deprecated: false
  }
];

export default function Header({ publicPage }: { publicPage?: boolean }) {
  const location = useLocation();
  const { user } = useUserContext();
  const currentUserGroups = useAppSelector(selectUserGroups);
  const handleLogout = () => {
    lightSignOut();
  };
  return (
    <div className="bg-white p-4 border-primary-700 border-b-4 shadow-header">
      <div className="container mx-auto flex flex-row gap-10 items-center">
        <a href="" className="flex-none w-40">
          <Logo />
        </a>
        <div className="grow">
          {!publicPage && (
            <ul className="flex flex-row font-medium items-center gap-1">
              {menuItems.map(
                (item) =>
                  currentUserGroups &&
                  currentUserGroups.some((group) => item.userGroups.includes(group)) &&
                  (item.link && item.pathname ? (
                    <MenuItem
                      key={item.link}
                      to={item.link}
                      active={!item.deprecated && location.pathname.indexOf(item.pathname) !== -1}
                      badge={item.badge}>
                      {item.label}
                    </MenuItem>
                  ) : item.subItems ? (
                    <MenuItemMegaMenu
                      key={item.label}
                      label={item.label}
                      menuItems={item.subItems}
                      badge={item.badge}
                      active={
                        !item.deprecated &&
                        item.subItems.some(
                          (subItem) => location.pathname.indexOf(subItem.pathname) !== -1
                        )
                      }
                    />
                  ) : null)
              )}
            </ul>
          )}
        </div>
        {!publicPage && (
          <div className="flex-none flex flex-row">
            <Tooltip
              content="Subscribe / Unsubscribe to push notifications"
              className="bg-black text-white"
              arrowClassName="fill-black"
              asChild>
              <PushSubscription />
            </Tooltip>
            <Tooltip content="Settings" className="bg-black text-white" arrowClassName="fill-black">
              <Button href="/settings" rounded link className="hover:bg-gray-50">
                <SettingsIcon className="w-5 h-5 stroke-primary-700" />
              </Button>
            </Tooltip>
            <Tooltip content="Logout" className="bg-black text-white" arrowClassName="fill-black">
              <Button onClick={() => handleLogout()} rounded link className="hover:bg-gray-50">
                <LogoutIcon className="w-5 h-5 stroke-primary-700" />
              </Button>
            </Tooltip>
            {user?.username ? (
              <Tooltip
                content={user.username}
                className="bg-black text-white"
                arrowClassName="fill-black">
                {user.emailHash ? (
                  <img
                    src={`https://gravatar.com/avatar/${user.emailHash}?d=robohash`}
                    className="h-10 w-10 ml-2 rounded-full border border-gray-300"
                  />
                ) : (
                  <AvatarFilledIcon className="w-10 h-10 ml-2 cursor-pointer" />
                )}
              </Tooltip>
            ) : (
              <AvatarFilledIcon className="w-10 h-10 ml-2" />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
