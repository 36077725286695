import Select from '../ui/Select';
import Button from '../ui/Button';

import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg?react';
import React from 'react';
import { FormItem } from './AssetQualityControlSteps';
import { Option } from '../../common/types';
import { getOptions } from '../../common/utils';
function StepValidationForm({
  onNavigate,
  question,
  value,
  disabled,
  setValue,
  canNavigateNext,
  canNavigatePrev
}: {
  onNavigate: (direction: 'next' | 'prev') => void;
  question: FormItem;
  value: string[] | undefined | null;
  setValue: (values: string[] | null) => void;
  canNavigateNext?: boolean;
  disabled?: boolean;
  canNavigatePrev?: boolean;
}) {
  const options = getOptions(question.options);
  const optionsValue = options.filter((option) => value?.includes(option.value));

  const handleOnChange = (selected: Option[] | Option) => {
    if (!Array.isArray(selected)) {
      setValue([selected.value]);
    } else {
      setValue(selected.map((option) => option.value));
    }
  };

  return (
    <div>
      <div className="text-sm mb-3">
        <p className="text-gray-700 mb-1.5 font-medium">{question.label}</p>
        <Select
          className="text-md mb-2"
          placeholder="Select observations"
          isDisabled={disabled}
          options={options}
          value={optionsValue}
          onChange={(selected) => handleOnChange(selected as Option[] | Option)}
        />
        {/*<div className="text-gray-600">Multiple observations can be selected</div>*/}
      </div>

      <div className={'flex justify-between mt-3'}>
        <Button
          variant={'defaultOutlined'}
          rounded
          size={'sm'}
          onClick={() => onNavigate('prev')}
          disabled={!canNavigatePrev}>
          <ArrowLeftIcon className={'h-4 w-4 stroke-gray-700'} />
          Previous
        </Button>
        <Button
          variant={'primary'}
          rounded
          size={'sm'}
          onClick={() => onNavigate('next')}
          disabled={!canNavigateNext || !value || value.length === 0}>
          Next <ArrowRightIcon className={'h-4 w-4 stroke-white'} />
        </Button>
      </div>
    </div>
  );
}

export default StepValidationForm;
