import { createSlice } from '@reduxjs/toolkit';
import { CatalogIdTypeDistributorMapping, RepositoryItemDetails } from './types';

const initialState: {
  repositoryItem?: RepositoryItemDetails;
  selectedRows: Set<number>;
} = {
  repositoryItem: undefined,
  selectedRows: new Set()
};
export const repositoryItemSlice = createSlice({
  name: 'repositoryItemSlice',
  initialState,
  reducers: {
    setRepositoryItem: (state, action) => {
      state.repositoryItem = action.payload;

      // If the repository starts with any of the options, set the catalogIdType to 'MPM'
      if (
        state.repositoryItem &&
        !state.repositoryItem.catalogIdType &&
        CatalogIdTypeDistributorMapping.MPM.some((option) =>
          state.repositoryItem?.repository?.startsWith(option)
        )
      ) {
        state.repositoryItem.catalogIdType = 'MPM';
      }
    },
    updateRepositoryItemExternalIds: (state, action) => {
      if (state.repositoryItem) {
        state.repositoryItem.eidrId = action.payload.eidrId;
        state.repositoryItem.titleCatalogId = action.payload.catalogId;
      }
    },
    addRepositoryItemHistoryMessage: (state, action) => {
      if (state.repositoryItem) {
        state.repositoryItem.history.push(action.payload);
      }
    }
  }
});

export const {
  setRepositoryItem,
  updateRepositoryItemExternalIds,
  addRepositoryItemHistoryMessage
} = repositoryItemSlice.actions;

export default repositoryItemSlice;
