import { Asset } from './types';

export interface QCCheck {
  id: string;
  playerCommand?: 'SEEK_TO_25' | 'SEEK_TO_50' | 'SEEK_TO_75';
  question: string;
  options?: { answer: string; value: string; conclusion: 'PASSED' | 'FAILED' }[];
}

export interface QCAutoCheck extends QCCheck {
  check: (baselineVideoAsset?: Asset, asset?: Asset) => string[];
}

export const qcChecks: {
  audio: { auto: QCAutoCheck[]; manual: QCCheck[] };
  video: { auto: QCAutoCheck[]; manual: QCCheck[] };
  subtitle: { auto: QCAutoCheck[]; manual: QCCheck[] };
  closedcaption: { auto: QCAutoCheck[]; manual: QCCheck[] };
} = {
  video: {
    auto: [
      {
        id: 'AUTO_VIDEO_QUALITY_CHECK',
        question: 'Check video quality',
        options: [
          { answer: 'Good', value: 'GOOD', conclusion: 'PASSED' },
          { answer: 'Poor', value: 'POOR', conclusion: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          if (!asset) return [];
          console.log('Checking video');
          if (asset.mediainfo?.length > 0) {
            asset.mediainfo.map((info) => {
              console.log(JSON.parse(info));
            });
          }
          return ['GOOD'];
        }
      },
      {
        id: 'AUTO_VIDEO_ASPECT_RATIO_CHECK',
        question: 'Check video aspect ratio',
        options: [
          { answer: 'Ok', value: 'OK', conclusion: 'PASSED' },
          { answer: 'Not ok', value: 'NOT_OK', conclusion: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          if (!asset) return [];
          return ['OK'];
        }
      },
      {
        id: 'AUTO_VIDEO_BIT_RATE_CHECK',
        question: 'Check video bit rate',
        options: [
          { answer: 'Ok', value: 'OK', conclusion: 'PASSED' },
          { answer: 'Not ok', value: 'NOT_OK', conclusion: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          if (!asset) return [];
          return ['OK'];
        }
      }
    ],
    manual: [
      {
        id: 'MANUAL_VIDEO_QUALITY_CHECK',
        question: 'Make sure the quality of the video is acceptable',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_VIDEO_25_CHECK',
        playerCommand: 'SEEK_TO_25',
        question: 'Check video 25% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_VIDEO_50_CHECK',
        playerCommand: 'SEEK_TO_50',
        question: 'Check video 50% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_VIDEO_75_CHECK',
        playerCommand: 'SEEK_TO_75',
        question: 'Check video 75% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      }
    ]
  },
  audio: {
    auto: [
      {
        id: 'AUTO_LANG_CHECK',
        question: 'Check correct language',
        options: [
          { answer: 'Correct', value: 'CORRECT', conclusion: 'PASSED' },
          { answer: 'Incorrect', value: 'INCORRECT', conclusion: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          if (!asset) return [];
          if (asset.mediainfo?.length > 0) {
            console.log('Checking audio');
            asset.mediainfo.map((info) => {
              console.log(JSON.parse(info));
            });
          }
          return ['CORRECT'];
        }
      },
      {
        id: 'AUTO_AUDIO_DURATION_CHECK',
        question: 'Check audio duration',
        options: [
          { answer: 'Ok', value: 'OK', conclusion: 'PASSED' },
          { answer: 'Not ok', value: 'NOT_OK', conclusion: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          if (!asset) return [];
          const baselineDuration = findPropertyAboveValue(
            baselineVideoAsset?.mediainfo,
            'Duration'
          );
          const duration = findPropertyAboveValue(asset.mediainfo, 'Duration');

          return baselineDuration === duration ? ['OK'] : ['NOT_OK'];
        }
      }
    ],
    manual: [
      {
        id: 'MANUAL_AUDIO_SYNC_25_CHECK',
        playerCommand: 'SEEK_TO_25',
        question: 'Check audio sync 25% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_AUDIO_50_CHECK',
        playerCommand: 'SEEK_TO_50',
        question: 'Check audio sync 50% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_AUDIO_75_CHECK',
        playerCommand: 'SEEK_TO_75',
        question: 'Check audio sync 75% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      }
    ]
  },
  subtitle: {
    auto: [
      {
        id: 'AUTO_SUBTITLE_DURATION_CHECK',
        question: 'Does all subtitles fit within the video duration?',
        options: [
          { answer: 'Ok', value: 'OK', conclusion: 'PASSED' },
          { answer: 'Not ok', value: 'NOT_OK', conclusion: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          if (!asset) return [];
          if (asset.mediainfo?.length > 0) {
            console.log('Checking subtitle');
            asset.mediainfo.map((info) => {
              console.log(JSON.parse(info));
            });
          }

          const baselineDuration = findPropertyAboveValue(
            baselineVideoAsset?.mediainfo,
            'Duration'
          );
          const duration = findPropertyAboveValue(asset.mediainfo, 'Duration');

          return baselineDuration === duration ? ['OK'] : ['NOT_OK'];
        }
      }
    ],
    manual: [
      {
        id: 'MANUAL_SUBTITLE_SYNC_25_CHECK',
        playerCommand: 'SEEK_TO_25',
        question: 'Check subtitle sync 25% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_SUBTITLE_50_CHECK',
        playerCommand: 'SEEK_TO_50',
        question: 'Check subtitle sync 50% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_SUBTITLE_75_CHECK',
        playerCommand: 'SEEK_TO_75',
        question: 'Check subtitle sync 75% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      }
    ]
  },
  closedcaption: {
    auto: [
      {
        id: 'AUTO_CLOSED_CAPTION_DURATION_CHECK',
        question: 'Does all captions fit within the video duration?',
        options: [
          { answer: 'Ok', value: 'OK', conclusion: 'PASSED' },
          { answer: 'Not ok', value: 'NOT_OK', conclusion: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          if (!asset) return [];
          if (asset.mediainfo?.length > 0) {
            console.log('Checking closed caption');
            asset.mediainfo.map((info) => {
              console.log(JSON.parse(info));
            });
          }
          return ['OK'];
        }
      }
    ],
    manual: [
      {
        id: 'MANUAL_SUBTITLE_SYNC_25_CHECK',
        playerCommand: 'SEEK_TO_25',
        question: 'Check subtitle sync 25% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_SUBTITLE_50_CHECK',
        playerCommand: 'SEEK_TO_50',
        question: 'Check subtitle sync 50% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_SUBTITLE_75_CHECK',
        playerCommand: 'SEEK_TO_75',
        question: 'Check subtitle sync 75% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', conclusion: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', conclusion: 'FAILED' }
        ]
      }
    ]
  }
};

//-- Helper methods for the QC checks

function findPropertyAboveValue(mediainfo: string[] | undefined, propName: string) {
  if (!mediainfo) return undefined;
  const property = mediainfo.find((info) => info.includes(propName));
  if (!property) return undefined;
  const obj = JSON.parse(property || '');

  // find property in object indepent of case
  const res = Object.keys(obj).find((key) => key.toLowerCase() === propName.toLowerCase());
  if (!res) return undefined;
  return obj[res];
}

export function extractFailedOptionValues(check: QCCheck): string[] {
  return (
    check.options
      ?.filter((option) => option.conclusion === 'FAILED')
      .map((option) => option.value) || []
  );
}
