import { graphql } from 'gql.tada';

export const getOrder = graphql(`
  query getOrder($id: ID!) {
    getOrderById(_id: $id) {
      _id
      poName
      organization
      sourceCatalog
      orderGroup {
        title
        clientId
        clientText
      }
      createdBy
      licenseStatus
      deliveryStatus
      titles {
        name
        titleType
        titleIndex
        titleCatalogId
        titleEidrId
        licenseStart
        licenseEnd
        dealName
        seriesName
        seasonNumber
        episodeNumber
        deliveries {
          deliveryId
          deliveryType
          deliveryFileName
          editVersion
          poDestination
          audioLanguages
          subtitleLanguages
          includeVideo
          requireHd
          require16x9
          status
          contentErrors {
            assetType
            language
            error
            description
          }
          history {
            user
            userFullName
            userOrganization
            userOrganizationName
            action
            message
            timestamp
          }
          ifeSystem
          ifeFormat
          vtkTemplate
          titleCard
          createdAt
          modifiedAt
        }
      }
      brand
      history {
        user
        userFullName
        userOrganization
        userOrganizationName
        action
        message
        timestamp
      }
      createdAt
      modifiedAt
      externalId
    }
  }
`);

export const getOrders = graphql(`
  query getOrders(
    $page: Int = 1
    $pageSize: Int = 30
    $search: String = ""
    $sortField: String = "createdAt"
    $sortOrder: SortOrder = DESC
    $orderGroupTitle: String
    $orderGroupClientId: String
    $licenseStatus: OrderLicenseStatus
    $deliveryStatus: OrderDeliveryStatus
  ) {
    listOrders(
      licenseStatus: $licenseStatus
      deliveryStatus: $deliveryStatus
      orderGroupTitle: $orderGroupTitle
      orderGroupClientId: $orderGroupClientId
      search: $search
      sortField: $sortField
      sortOrder: $sortOrder
      page: $page
      pageSize: $pageSize
    ) {
      results {
        _id
        poName
        organization
        sourceCatalog
        orderGroup {
          title
          clientId
          clientText
        }
        createdBy
        licenseStatus
        deliveryStatus
        titles {
          name
          titleType
          titleIndex
          titleCatalogId
          titleEidrId
          licenseStart
          licenseEnd
          dealName
          seriesName
          seasonNumber
          episodeNumber
          deliveries {
            deliveryId
            deliveryType
            deliveryFileName
            editVersion
            poDestination
            audioLanguages
            subtitleLanguages
            includeVideo
            requireHd
            require16x9
            status
            contentErrors {
              assetType
              language
              error
              description
            }
            history {
              user
              userFullName
              userOrganization
              action
              message
              timestamp
            }
            ifeSystem
            ifeFormat
            vtkTemplate
            titleCard
            createdAt
            modifiedAt
          }
        }
        brand
        history {
          user
          userFullName
          userOrganization
          action
          message
          timestamp
        }
        createdAt
        modifiedAt
        externalId
      }
      meta {
        page
        pageSize
        total
      }
    }
  }
`);

export const getOrderGroups = graphql(`
  query getOrderGroups($orgId: String) {
    listOrderGroups(orgId: $orgId) {
      _id
      orgId
      title
      clientId
      clientText
      createdAt
    }
  }
`);
