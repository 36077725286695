/** @type {import('tailwindcss').Config} */
import defaultTheme from 'tailwindcss/defaultTheme';
export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      colors: {
        primary: '#006FA2',
        'primary-950': '#003052',
        'primary-900': '#003C66',
        'primary-800': '#004D74',
        'primary-700': '#00496A',
        'primary-600': '#006FA2',
        'primary-500': '#3A8FB7',
        'primary-400': '#73AFCC',
        'primary-300': '#ADCFE1',
        'primary-200': '#CBDFEA',
        'primary-100': '#D7E6EE',
        'primary-50': '#F0F8FF',
        'primary-25': '#FCFDFD',

        danger: '#D92D20',
        'danger-900': '#7A271A',
        'danger-800': '#912018',
        'danger-700': '#B42318',
        'danger-600': '#D92D20',
        'danger-500': '#F04438',
        'danger-400': '#F97066',
        'danger-300': '#FDA29B',
        'danger-200': '#FECDCA',
        'danger-100': '#FEE4E2',
        'danger-50': '#FEF3F2',
        'danger-25': '#FFFBFA',

        warning: '#DC6803',
        'warning-900': '#7A2E0E',
        'warning-800': '#93370D',
        'warning-700': '#B54708',
        'warning-600': '#DC6803',
        'warning-500': '#F79009',
        'warning-400': '#FDB022',
        'warning-300': '#FEC84B',
        'warning-200': '#FEDF89',
        'warning-100': '#FEF0C7',
        'warning-50': '#FFFAEB',
        'warning-25': '#FFFCF5',

        success: '#079455',
        'success-900': '#074D31',
        'success-800': '#085D3A',
        'success-700': '#067647',
        'success-600': '#079455',
        'success-500': '#17B26A',
        'success-400': '#47CD89',
        'success-300': '#75E0A7',
        'success-200': '#A9EFC5',
        'success-100': '#DCFAE6',
        'success-50': '#ECFDF3',
        'success-25': '#F6FEF9',
        'success-10': '#C2ECD0',

        gray: '#475467',
        'gray-900': '#101828',
        'gray-800': '#1D2939',
        'gray-700': '#344054',
        'gray-600': '#475467',
        'gray-500': '#667085',
        'gray-400': '#98A2B3',
        'gray-300': '#D0D5DD',
        'gray-250': '#E4E7EC',
        'gray-200': '#EAECF0',
        'gray-100': '#F2F4F7',
        'gray-50': '#F9FAFB',
        'gray-25': '#FCFCFD',
        'gray-10': '#88A0C0'
      },
      boxShadow: {
        xs: '0 1px 2px rgba(16, 24, 40, 0.05)',
        sm: ['0 1px 3px rgba(16, 24, 40, 0.10)', '0 1px 2px rgba(16, 24, 40, 0.06)'],
        md: ['0 4px 8px rgba(16, 24, 40, 0.10)', '0 2px 4px rgba(16, 24, 40, 0.06)'],
        lg: ['0 12px 16px rgba(16, 24, 40, 0.8)', '0 4px 6px rgba(16, 24, 40, 0.03)'],
        xl: ['0 20px 24px rgba(16, 24, 40, 0.8)', '0 8px 8px rgba(16, 24, 40, 0.03)'],
        '2xl': '0 24px 48px rgba(16, 24, 40, 0.18)',
        '3xl': '0 32px 64px rgba(16, 24, 40, 0.14)',
        btn: ['0 1px 2px rgba(16, 24, 40, 0.10)', '0px 0px 4px #F2F4F7'],
        'btn-primary': ['0 1px 2px rgba(16, 24, 40, 0.10)', '0px 0px 4px rgba(131, 216, 255, 0.5)'],
        'btn-danger': ['0 1px 2px rgba(16, 24, 40, 0.10)', '0px 0px 4px #FEE4E2'],
        select: ['0 1px 2px rgba(16, 24, 40, 0.05)', '0px 0px 4px #83D8FF'],
        checkbox: ['0px 0px 4px #F0F8FF'],
        input: ['0 1px 2px rgba(16, 24, 40, 0.10)', '0px 0px 4px rgba(131, 216, 255, 0.5)'],
        'input-danger': ['0 1px 2px rgba(16, 24, 40, 0.10)', '0px 0px 4px #FEE4E2'],
        header: ['0 1px 2px rgba(16, 24, 40, 0.10)', '1px 0px 15px #00496A20']
      },
      fontFamily: {
        sans: ['Inter var', ...defaultTheme.fontFamily.sans]
      },
      padding: {
        '4.5': '18px'
      },
      backgroundImage: {
        'login-bg': "url('/src/assets/login-bg.png')",
        'table-empty-bg': "url('/src/assets/table-empty-bg.svg')"
      },
      width: {
        '128': '568px'
      }
    }
  },
  plugins: []
};
