import React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import clsx from 'clsx';

// Example usage
// <ProgressBar value={50} max={100} label={true} labelPosition="right" className="custom-class" />

type ProgressBarProps = {
  value: number;
  max?: number;
  label?: boolean;
  labelPosition?: 'right' | 'bottom-right';
  className?: string;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  max = 100,
  label = true,
  labelPosition = 'right',
  className = ''
}) => {
  const percentage = Math.round((value / max) * 100);
  return (
    <div
      className={clsx('flex ', className, {
        'flex-col items-end justify-center': labelPosition === 'bottom-right',
        'items-center': labelPosition !== 'bottom-right'
      })}>
      <ProgressPrimitive.Root
        className="relative w-full h-2 bg-gray-250 rounded my-1.5"
        value={value}
        max={max}>
        <ProgressPrimitive.Indicator
          className="h-full bg-primary rounded"
          style={{ width: `${percentage}%` }}
        />
      </ProgressPrimitive.Root>

      {label && (labelPosition === 'right' || labelPosition === 'bottom-right') && (
        <span
          className={clsx('text-sm text-gray-700', {
            'ml-3': labelPosition === 'right',
            'mt-0.5': labelPosition === 'bottom-right'
          })}>
          {percentage}%
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
