//-------------------------------------------------------------------
// workflow graphql client
//-------------------------------------------------------------------
export const getPO = `
  query GetPOQuery($poName: String!) {
    list_POs(input: {filter: {po_name: {eq: $poName}}}) {
      id
      pos {
        poitems {
          po_item_id
        }
      }
    }
  }
`;

export const getPOItem = `
  query GetPOItemQuery($poName: String!, $poItemId: String!) {
    list_POs(input: {filter: {po_name: {eq: $poName}}}) {
      pos {
        poitems(po_item_id: $poItemId) {
          po_item_id
          publish_process {
            id
            state
            message
          }
          workflow_process {
            id
            state
            message
          }
          preview {
            dash_manifest_url
            drmtoday_token
          }
        }
      }
    }
  }
`;

//-------------------------------------------------------------------
// repository graphql client
//-------------------------------------------------------------------
export const getProcess = `
  query Process($id: ID!) {
      process(id: $id) {
          id
          state
          data
          message
          action
          start_date
          end_date
      }
  }
`;

export const previewGenerate = `
  mutation preview_generate($folder_id: ID!) {
      previewGenerate(input: { folder_id: $folder_id }) {
          id
          state
          data
          message
          action
          start_date
          end_date
      }
  }
`;

export const previewGenerateToken = `
  mutation preview_generation($asset_id: String) {
    previewGenerateToken(input: { asset_id: $asset_id } ) {
      drmtoday_token
      asset_id
      dash_url
    }
  }
`;
