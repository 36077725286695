import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store';
import { updateRepositoryItemExternalIds } from '../../features/repository/repositoryItemSlice';
import { UpdateRepositoryItemExternalIdsInput } from '../../features/repository/types';
import Input from '../ui/Input';
import Button from '../ui/Button';
import CheckDoneIcon from '../../assets/icons/check-done.svg?react';
import SaveIcon from '../../assets/icons/save.svg?react';
import ExternalLinkIcon from '../../assets/icons/external-link.svg?react';

export default function EditRepositoryItemExternalIdsForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { repositoryItem } = useSelector((state: RootState) => state.repositoryItem);

  const { register, handleSubmit, setValue } = useForm<UpdateRepositoryItemExternalIdsInput>({
    defaultValues: {
      catalogId: '',
      catalogIdType: 'MPM',
      eidrId: ''
    }
  });

  useEffect(() => {
    if (repositoryItem) {
      setValue('catalogId', repositoryItem.titleCatalogId);
      setValue('eidrId', repositoryItem.eidrId);
    }
  }, [repositoryItem, setValue]);

  const onFormSubmit = handleSubmit((data) => {
    dispatch(updateRepositoryItemExternalIds(data));
  });
  if (!repositoryItem) {
    return null;
  }
  return (
    <form className="flex flex-col gap-5" onSubmit={onFormSubmit}>
      {repositoryItem?.catalogIdType && (
        <Section
          label={`${repositoryItem.catalogIdType} ID`}
          placeholder="Enter catalog id here"
          valueKey="catalogId"
          href={getWbtvdLink(repositoryItem.titleCatalogId)}
          register={register}
        />
      )}

      <Section
        label="EIDR ID"
        placeholder="Enter EIDR ID here"
        valueKey="eidrId"
        href={getEidrLink(repositoryItem.eidrId)}
        register={register}
      />

      <div className="flex justify-between mt-4">
        <Button
          variant="defaultOutlined"
          rounded
          onClick={() => navigate(`${location.pathname}/quality-control`)}
          aria-label="Quality Check">
          <CheckDoneIcon className="stroke-gray-600 h-5 w-5" />
          <span className="font-semibold text-gray-700">QC this asset</span>
        </Button>
        <Button variant="primary" rounded aria-label="Save Changes">
          <SaveIcon className="stroke-white h-5 w-5" />
          <span className="font-semibold">Save</span>
        </Button>
      </div>
    </form>
  );
}

interface SectionProps {
  label: string;
  placeholder: string;
  valueKey: keyof UpdateRepositoryItemExternalIdsInput;
  href: string;
  register: ReturnType<typeof useForm>['register'];
}

const Section: React.FC<SectionProps> = ({ label, placeholder, valueKey, href, register }) => (
  <>
    <div className="flex items-center">
      <div className="basis-1/4">
        <label className="text-gray-700 font-semibold">{label}</label>
      </div>
      <div className="basis-3/4 flex gap-8 items-center">
        <Input rounded placeholder={placeholder} {...register(valueKey)} />
        <LinkOrPlaceholder href={href} />
      </div>
    </div>
    <div className="w-full bg-gray-100 h-px my-2" />
  </>
);

const getWbtvdLink = (catalogId?: string) =>
  catalogId?.trim() ? `https://www.wbtvd.com/search?q=${catalogId}` : '';

const getEidrLink = (eidrId?: string) =>
  eidrId?.trim() ? `https://ui.eidr.org/view/content?id=${eidrId}` : '';

interface LinkOrPlaceholderProps {
  href: string;
}

const LinkOrPlaceholder: React.FC<LinkOrPlaceholderProps> = ({ href }) => {
  if (!href) {
    return <ExternalLinkIcon className="stroke-gray-300 h-5 w-5 cursor-not-allowed" />;
  }

  return (
    <Link to={href} target="_blank" aria-label="External Link">
      <ExternalLinkIcon className="stroke-gray-600 h-5 w-5" />
    </Link>
  );
};
