import { graphql } from 'gql.tada';

export const listRepositoryItems = graphql(`
  query ListRepositoryItems(
    $distributor: Distributor!
    $repository: Repository
    $qcStatus: ListRepositoryItemsQcFilter
    $parentId: ID = null
    $seasonNumber: Int = null
    $page: Int = 1
    $pageSize: Int = 30
  ) {
    listRepositoryItems(
      distributor: $distributor
      repository: $repository
      qcStatus: $qcStatus
      parentId: $parentId
      seasonNumber: $seasonNumber
      page: $page
      pageSize: $pageSize
    ) {
      results {
        _id
        title
        itemType
        repository
        eidrId
        titleCatalogId
        catalogIdType
        editVersion
        editEidrId
        folderLocation
        parentId
        seasonNumber
        episodeNumber
        releaseDate
        createdAt
        modifiedAt
        hasAssetToQc
        hasAssetToConform
        series {
          _id
          title
          itemType
          repository
          eidrId
          titleCatalogId
          catalogIdType
        }
      }
      meta {
        all
        page
        pageSize
        lastPage
      }
    }
  }
`);

export const getRepositoryItemAndAssets = graphql(`
  query GetRepositoryItemAndAssets($_id: ID!) {
    getRepositoryItemById(_id: $_id) {
      _id
      title
      itemType
      repository
      eidrId
      titleCatalogId
      catalogIdType
      editVersion
      editEidrId
      folderLocation
      parentId
      seasonNumber
      episodeNumber
      releaseDate
      createdAt
      modifiedAt
      history {
        user
        userFullName
        userOrganization
        userOrganizationName
        action
        message
        timestamp
      }
    }
    listAssets(repositoryItemId: $_id) {
      _id
      location
      type
      status
      language
      repositoryItemId
      size
      mediainfo
      statusModifiedAt
      modifiedAt
      tracks {
        index
        trackType
        language
        enabled
      }
      createdAt
      history {
        user
        userFullName
        userOrganization
        userOrganizationName
        action
        message
        timestamp
      }
    }
  }
`);

export const getRepositoryItemAssets = graphql(`
  query GetRepositoryItemAssets($_id: ID!) {
    listAssets(repositoryItemId: $_id) {
      _id
      location
      type
      status
      language
      repositoryItemId
      size
      mediainfo
      statusModifiedAt
      modifiedAt
      createdAt
      history {
        user
        userFullName
        userOrganization
        userOrganizationName
        action
        message
        timestamp
      }
    }
  }
`);
