import React from 'react';
import clsx from 'clsx';

import ProgressStepAutoFail from '../../assets/icons/progress-step-auto-fail.svg?react';
import ProgressStepAutoPass from '../../assets/icons/progress-step-auto-pass.svg?react';
import ProgressStepManualFail from '../../assets/icons/progress-step-manual-fail.svg?react';
import ProgressStepManualPass from '../../assets/icons/progress-step-manual-pass.svg?react';
import ProgressStepTodo from '../../assets/icons/progress-step-todo.svg?react';
import ProgressStepActive from '../../assets/icons/progress-step-active.svg?react';

export interface FormItem {
  label: string;
  options: { [key: string]: string };
  badOptionKeys: string[];
}

export interface AssetQualityControlStep {
  id: string;
  title: string;
  description?: string;
  type: 'manual' | 'auto';
  form: FormItem;
}

interface AssetQCReport {
  [key: string]: string[] | null | undefined;
}

interface AssetQualityControlStepsProps {
  className?: string;
  steps: AssetQualityControlStep[];
  selected?: string;
  setSelected?: (id: string) => void;
  qcReport: AssetQCReport;
}

/**
 * Determines which icon to display for each step based on whether it's active,
 * and the data in the qcReport.
 */
const Border = () => (
  <div
    className={
      'absolute w-[calc(100%+10px)] h-[calc(100%+10px)] ' +
      'border-primary-500 border-2 rounded-full ' +
      'left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2'
    }
  />
);

function getStepIcon(step: AssetQualityControlStep, isActive: boolean, qcReport: AssetQCReport) {
  // Show the "active" icon if this is the currently selected step

  const qcResponse = qcReport[step.id] ?? [];

  if (isActive) {
    if (!qcResponse.length) {
      return (
        <div className="relative h-6 w-6">
          <ProgressStepActive className="h-full w-full" />
          <Border />
        </div>
      );
    } else {
      return (
        <div className="relative h-6 w-6">
          {getStepIcon(step, false, qcReport)}
          <Border />
        </div>
      );
    }
  }

  // If there's no QC response, show the TODO icon
  if (qcResponse.length === 0) {
    return <ProgressStepTodo className="w-6 h-6" />;
  }

  // Determine pass or fail icon
  const hasFailingOption = qcResponse.some((response) =>
    step.form.badOptionKeys.includes(response)
  );
  if (hasFailingOption) {
    return step.type === 'auto' ? (
      <ProgressStepAutoFail className="w-6 h-6" />
    ) : (
      <ProgressStepManualFail className="w-6 h-6" />
    );
  }
  return step.type === 'auto' ? (
    <ProgressStepAutoPass className="w-6 h-6" />
  ) : (
    <ProgressStepManualPass className="w-6 h-6" />
  );
}

/**
 * Renders a vertical list of steps for asset quality control. Each step displays
 * an icon based on whether it is active, completed (pass/fail), or still pending.
 */
const AssetQualityControlSteps: React.FC<AssetQualityControlStepsProps> = ({
  className,
  steps,
  selected,
  setSelected,
  qcReport
}) => {
  /*----- BEGIN Helpers for step click-----*/
  // Only call setSelected if defined and the step is not currently selected
  const handleStepClick = (stepId: string, isCurrentlySelected: boolean) => {
    if (setSelected && !isCurrentlySelected) {
      setSelected(stepId);
    }
  };

  // Can only navigate to a step if the previous step has been completed
  const canNavigate = (stepId: string) => {
    const stepIndex = steps.findIndex((step) => step.id === stepId);
    if (stepIndex === 0) {
      return true;
    }
    const previousStep = steps[stepIndex - 1];
    const previousStepResponse = qcReport[previousStep.id] ?? [];
    return previousStepResponse.length > 0;
  };
  /*----- END Helpers for step click-----*/

  return (
    <div className={clsx('flex flex-col gap-1.5 max-h-max', className)}>
      {steps.map((step, index) => {
        const isStepSelected = selected === step.id;
        return (
          <div
            key={step.id}
            className={clsx('relative flex gap-3', {
              'cursor-pointer': canNavigate(step.id),
              'cursor-not-allowed': !canNavigate(step.id)
            })}
            onClick={() => handleStepClick(step.id, isStepSelected)}>
            <div className="flex flex-col">
              {/* Icon */}
              <div className="h-6 w-6">{getStepIcon(step, isStepSelected, qcReport)}</div>

              {/* Connector Line (except for the last item) */}
              {index < steps.length - 1 && (
                <div className="flex flex-grow items-center justify-center relative h-full mt-1.5 -top-px">
                  <span className="bg-primary-500 w-0.5 h-full" />
                </div>
              )}
            </div>

            {/* Step Title & Description */}
            <div>
              <div className="text-sm font-semibold text-gray-700 mb-4 min-h-8">{step.title}</div>
              {/*<div className="text-sm text-gray-600 mb-4">{step.description}</div>*/}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AssetQualityControlSteps;
