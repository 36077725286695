import AacIconDefault from '../../assets/icons/filetype/filetype-aac-default.svg?react';
import AifIconDefault from '../../assets/icons/filetype/filetype-aif-default.svg?react';
import AiffIconDefault from '../../assets/icons/filetype/filetype-aiff-default.svg?react';
import CsvIconDefault from '../../assets/icons/filetype/filetype-csv-default.svg?react';
import MovIconDefault from '../../assets/icons/filetype/filetype-mov-default.svg?react';
import Mp2IconDefault from '../../assets/icons/filetype/filetype-mp2-default.svg?react';
import Mp4IconDefault from '../../assets/icons/filetype/filetype-mp4-default.svg?react';
import MpaIconDefault from '../../assets/icons/filetype/filetype-mpa-default.svg?react';
import MpgIconDefault from '../../assets/icons/filetype/filetype-mpg-default.svg?react';
import MpvIconDefault from '../../assets/icons/filetype/filetype-mpv-default.svg?react';
import QtIconDefault from '../../assets/icons/filetype/filetype-qt-default.svg?react';
import RtfIconDefault from '../../assets/icons/filetype/filetype-rtf-default.svg?react';
import SccIconDefault from '../../assets/icons/filetype/filetype-scc-default.svg?react';
import SrtIconDefault from '../../assets/icons/filetype/filetype-srt-default.svg?react';
import StlIconDefault from '../../assets/icons/filetype/filetype-stl-default.svg?react';
import TtmlIconDefault from '../../assets/icons/filetype/filetype-ttml-default.svg?react';
import WavIconDefault from '../../assets/icons/filetype/filetype-wav-default.svg?react';
import WmaIconDefault from '../../assets/icons/filetype/filetype-wma-default.svg?react';
import WmvIconDefault from '../../assets/icons/filetype/filetype-wmv-default.svg?react';
import XmlIconDefault from '../../assets/icons/filetype/filetype-xml-default.svg?react';
import EmptyIconDefault from '../../assets/icons/filetype/filetype-empty-default.svg?react';
import React, { ReactElement, SVGProps } from 'react';

const extensionIconDefaultMap: Record<string, React.FC<SVGProps<SVGSVGElement>>> = {
  //Video
  mp4: Mp4IconDefault,
  mov: MovIconDefault,
  wmv: WmvIconDefault,
  mpg: MpgIconDefault,
  mpv: MpvIconDefault,
  qt: QtIconDefault,
  // Subtitles
  stl: StlIconDefault,
  scc: SccIconDefault,
  srt: SrtIconDefault,
  ttml: TtmlIconDefault,
  // Audio
  aac: AacIconDefault,
  wma: WmaIconDefault,
  aif: AifIconDefault,
  aiff: AiffIconDefault,
  wav: WavIconDefault,
  mpa: MpaIconDefault,
  mp2: Mp2IconDefault,
  // Document
  csv: CsvIconDefault,
  rtf: RtfIconDefault,
  xml: XmlIconDefault
};
export default function FileTypeIcon({
  extension,
  ...props
}: {
  // File extension without the dot
  extension: string;
} & SVGProps<SVGSVGElement>): ReactElement | null {
  const Icon = extensionIconDefaultMap[extension];
  if (Icon) {
    return <Icon {...props} />;
  }
  return <EmptyIconDefault {...props} />;
}
