import React from 'react';

export interface BadgeProps {
  size?: 'sm' | 'md' | 'lg';
  type?: 'pill-color' | 'pill-outline' | 'badge-color' | 'badge-modern';
  icon?: 'dot' | 'cross' | 'custom' | null;
  customIcon?: React.ReactNode;
  color?: 'warning' | 'gray' | 'success' | 'error';
  text: string;
}

export default function Badge(props: BadgeProps) {
  const size = props.size || 'md';
  const type = props.type || 'pill-color';
  const icon = props.icon;
  const color = props.color || 'warning';

  const text = props.text;
  const schemaType = type === 'pill-color' || type === 'badge-color' ? 'normal' : 'outline';
  const colorSchema = badgeColorSchema[color + '_' + schemaType];

  const sizeSchema = badgeSizeSchema[size];

  function GetIcon() {
    switch (icon) {
      case 'dot':
        return <span className={`${colorSchema.iconBgColor} rounded-full h-1.5 w-1.5`}></span>;
      case 'cross':
        return (
          <span className={colorSchema.iconTextColor}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={sizeSchema.iconSize}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        );
      case 'custom':
      default:
        if (props.customIcon) return props.customIcon;
        return null;
    }
  }

  // Find the badge color schema

  const borderClass = type === 'pill-color' || type === 'pill-outline' ? 'rounded-full' : 'rounded';

  function GetBadgeRounded() {
    switch (type) {
      case 'pill-color':
      case 'pill-outline':
        return 'rounded-full';
      case 'badge-color':
      case 'badge-modern':
      default:
        return 'rounded';
    }
  }

  return (
    <span
      className={`${sizeSchema.badgePadding} ${colorSchema.bgColor} flex items-center border ${GetBadgeRounded()} ${borderClass} ${colorSchema.borderColor} w-max gap-1.5`}>
      {GetIcon()}
      <span className={`${colorSchema.textColor} font-medium ${sizeSchema.fontSize}`}>{text}</span>
    </span>
  );
}

interface BadgeColorSchema {
  bgColor: string;
  textColor: string;
  borderColor: string;
  iconBgColor: string;
  iconTextColor?: string;
}

const badgeColorSchema: Record<string, BadgeColorSchema> = {
  warning_normal: {
    borderColor: 'border-warning-200',
    bgColor: 'bg-warning-50',
    textColor: 'text-warning-700',
    iconBgColor: 'bg-warning-500',
    iconTextColor: 'text-warning-500'
  },
  warning_outline: {
    borderColor: 'border-warning-700',
    bgColor: 'bg-transparent',
    textColor: 'text-warning-700',
    iconBgColor: 'bg-warning-600',
    iconTextColor: 'bg-warning-600'
  },
  gray_normal: {
    borderColor: 'border-gray-200',
    bgColor: 'bg-gray-50',
    textColor: 'text-gray-700',
    iconBgColor: 'bg-gray-500',
    iconTextColor: 'text-gray-500'
  },
  success_normal: {
    borderColor: 'border-success-200',
    bgColor: 'bg-success-50',
    textColor: 'text-success-700',
    iconBgColor: 'bg-success-500',
    iconTextColor: 'text-success-500'
  },
  success_outline: {
    borderColor: 'border-success-700',
    bgColor: 'bg-transparent',
    textColor: 'text-success-700',
    iconBgColor: 'bg-success-600',
    iconTextColor: 'bg-success-600'
  },
  error_normal: {
    borderColor: 'border-danger-200',
    bgColor: 'bg-danger-50',
    textColor: 'text-danger-700',
    iconBgColor: 'bg-danger-500',
    iconTextColor: 'text-danger-500'
  },
  error_outline: {
    borderColor: 'border-danger-700',
    bgColor: 'bg-transparent',
    textColor: 'text-danger-700',
    iconBgColor: 'bg-danger-600',
    iconTextColor: 'text-danger-600'
  }
};

interface BadgeSizeSchema {
  badgePadding: string;
  iconSize: string;
  fontSize: string;
}

const badgeSizeSchema: Record<string, BadgeSizeSchema> = {
  sm: {
    badgePadding: 'px-1.5 py-0.5',
    iconSize: 'h-2 w-2',
    fontSize: 'text-xs'
  },
  md: {
    badgePadding: 'px-2 py-0.5',
    iconSize: 'h-3 w-3',
    fontSize: 'text-sm'
  },
  lg: {
    badgePadding: 'px-3 py-1.5',
    iconSize: 'h-4 w-4',
    fontSize: 'text-sm'
  }
};
