import { graphql } from 'gql.tada';

export const onAddOrderMessage = graphql(`
  subscription OnAddOrderMessage($id: ID!) {
    onAddOrderMessage(_id: $id) {
      _id
      user
      userFullName
      userOrganization
      userOrganizationName
      action
      message
      timestamp
    }
  }
`);

export const onCreateOrder = graphql(`
  subscription OnCreateOrder($organization: String!) {
    onCreateOrder(organization: $organization) {
      _id
      poName
      organization
      sourceCatalog
      orderGroup {
        title
        clientId
        clientText
      }
      createdBy
      licenseStatus
      deliveryStatus
      titles {
        name
        titleType
        titleIndex
        titleCatalogId
        titleEidrId
        licenseStart
        licenseEnd
        dealName
        seriesName
        seasonNumber
        episodeNumber
        deliveries {
          deliveryId
          deliveryType
          deliveryFileName
          editVersion
          poDestination
          audioLanguages
          subtitleLanguages
          includeVideo
          requireHd
          require16x9
          status
          contentErrors {
            assetType
            language
            error
            description
          }
          history {
            user
            userFullName
            userOrganization
            userOrganizationName
            action
            message
            timestamp
          }
          ifeSystem
          ifeFormat
          vtkTemplate
          titleCard
          createdAt
          modifiedAt
        }
      }
      brand
      history {
        user
        userFullName
        userOrganization
        userOrganizationName
        action
        message
        timestamp
      }
      createdAt
      modifiedAt
      externalId
    }
  }
`);

export const onUpdateOrder = graphql(`
  subscription OnUpdateOrder($organization: String!, $id: ID!) {
    onUpdateOrder(organization: $organization, _id: $id) {
      _id
      organization
      licenseStatus
      deliveryStatus
      externalId
      brand
      titles {
        deliveries {
          deliveryId
          status
          audioLanguages
          subtitleLanguages
          deliveryFileName
          history {
            user
            userFullName
            userOrganization
            userOrganizationName
            action
            message
            timestamp
          }
        }
      }
    }
  }
`);

export const onUpdateOrdersForOrganization = graphql(`
  subscription OnUpdateOrdersForOrganization($organization: String!) {
    onUpdateOrder(organization: $organization) {
      _id
      organization
      licenseStatus
      deliveryStatus
      externalId
      brand
      titles {
        deliveries {
          deliveryId
          status
        }
      }
    }
  }
`);
