import Badge from '../ui/Badge';
import Button from '../ui/Button';
import React, { ReactElement } from 'react';

import MenuIcon from '../../assets/icons/menu-02.svg?react';
import EpisodeIcon from '../../assets/icons/episode.svg?react';
import MovieIcon from '../../assets/icons/movie.svg?react';
import SeriesIcon from '../../assets/icons/series.svg?react';
import ShortIcon from '../../assets/icons/short.svg?react';
import DocumentaryIcon from '../../assets/icons/documentary.svg?react';
import SpecialIcon from '../../assets/icons/special.svg?react';
import { RepositoryItemType, RepositoryItemTypeLabels } from '../../features/repository/types';

export default function MetadataRichDetailPageHeader({
  title,
  titleType,
  historyVisible,
  setHistoryVisible
}: {
  title: string;
  titleType: RepositoryItemType;
  historyVisible?: boolean;
  setHistoryVisible?: (value: boolean) => void;
}) {
  const iconsMap: Record<RepositoryItemType, ReactElement> = {
    SERIES: <SeriesIcon className="stroke-gray-300 h-3.5 w-3.5" />,
    MOVIE: <MovieIcon className="stroke-gray-300 h-3.5 w-3.5" />,
    EPISODE: <EpisodeIcon className="stroke-gray-300 h-3.5 w-3.5" />,
    SHORT: <ShortIcon className="stroke-gray-300 h-3.5 w-3.5" />,
    DOCUMENTARY: <DocumentaryIcon className="fill-gray-300 h-3.5 w-3.5" />,
    SPECIAL: <SpecialIcon className="fill-gray-300 h-3.5 w-3.5" />
  };
  return (
    <div className="px-5 pt-5 pb-3 flex flex-row gap-4 items-center justify-center min-h-24 border-b border-gray-200">
      <div className="grow gap-2 flex flex-row items-center justify-between font-semibold text-lg text-gray-900">
        <div>
          <div className={'mb-2 text-gray mt-4'}>{title}</div>
          {iconsMap[titleType as RepositoryItemType] && (
            <Badge
              text={RepositoryItemTypeLabels[titleType]}
              icon={'custom'}
              customIcon={iconsMap[titleType as RepositoryItemType]}
              color={'gray'}
              size={'sm'}
            />
          )}
        </div>
        {setHistoryVisible && (
          <Button rounded size={'sm'} onClick={() => setHistoryVisible(!historyVisible)}>
            <MenuIcon />
            <span className={'text-sm'}>{historyVisible ? 'Hide' : 'Show'} history</span>
          </Button>
        )}
      </div>
    </div>
  );
}
