import React, { forwardRef, Ref } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { cva, type VariantProps } from 'class-variance-authority';

/*
How to use it:
  <Input
    rounded
    label="Enter your username?"
    placeholder="Username"
    hint="This is a hint text to help user."
    error="Please enter username"
    suffixNode={<CheckIcon className="w-4 h-4 stroke-black" />}
    prefixNode={<CheckIcon className="w-4 h-4 stroke-black" />}
  />
*/
const inputStyle = cva(
  ['flex flex-row gap-1 items-center justify-center border w-full text-gray-900'],
  {
    variants: {
      variant: {
        default: ['border-gray-300', 'focus:shadow-input focus:border-primary-300']
      },
      inputSize: {
        sm: ['text-sm py-2 px-2.5'],
        md: ['text-md py-2.5 px-3']
      }
    },
    defaultVariants: {
      variant: 'default',
      inputSize: 'md'
    }
  }
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputStyle> {
  rounded?: boolean;
}

const Input = forwardRef(
  (
    {
      className,
      variant,
      inputSize,
      disabled,
      rounded,
      prefixNode,
      suffixNode,
      error,
      label,
      hint,
      showErrorBorder = false,
      ...props
    }: InputProps & {
      prefixNode?: React.ReactNode;
      suffixNode?: React.ReactNode;
      error?: string | React.ReactNode;
      showErrorBorder?: boolean;
      label?: string | React.ReactNode;
      hint?: string | React.ReactNode;
    },
    forwardRef: Ref<HTMLInputElement>
  ) => {
    const inputClasses = clsx(
      'outline-0 transition',
      {
        'cursor-pointer opacity-100': typeof disabled === 'undefined' || disabled === false,
        'cursor-not-allowed opacity-30': disabled,
        'rounded-lg': rounded,
        'pl-10': prefixNode,
        'pr-10': suffixNode,
        'shadow-input-danger border-danger-500 focus:shadow-input-danger focus:border-danger-500':
          error || showErrorBorder
      },
      className
    );
    return (
      <div className="w-full">
        {label && <div className="text-gray-700 mb-1.5 text-sm font-medium">{label}</div>}
        <div className="relative">
          {prefixNode && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">{prefixNode}</div>
          )}
          <input
            className={twMerge(
              inputStyle({
                variant,
                inputSize,
                className: inputClasses
              })
            )}
            disabled={disabled}
            {...props}
            ref={forwardRef}
          />
          {suffixNode && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">{suffixNode}</div>
          )}
        </div>
        {error && <div className="text-danger-500 mt-1.5 text-sm">{error}</div>}
        {hint && <div className="text-gray-600 mt-1.5 text-sm">{hint}</div>}
      </div>
    );
  }
);
Input.displayName = 'Input';

export default Input;
