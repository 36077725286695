import { configureStore } from '@reduxjs/toolkit';
import { commonApi } from '../features/common/commonApi';
import userGroupsSlice from '../features/userGroups/userGroupsSlice';
import sidebarSlice from '../features/sidebar/sidebarSlice';
import ingestSlice from '../features/ingestion/ingestSlice';
import catalogSlice from '../features/catalog/catalogSlice';
import catalogOrderSlice from '../features/catalog/catalogOrderSlice';
import ordersSlice from '../features/orders/ordersSlice';
import orderSlice from '../features/orders/orderSlice';
import organizationsSlice from '../features/organizations/organizationsSlice';
import usersSlice from '../features/users/usersSlice';
import { workflowApi } from '../features/orders/workflowApi';
import { castlabsRepositoryApi } from '../features/repository/castlabsRepositoryApi';
import repositoryItemsSlice from '../features/repository/repositoryItemsSlice';
import repositoryItemSlice from '../features/repository/repositoryItemSlice';

export const store = configureStore({
  reducer: {
    userGroups: userGroupsSlice.reducer,
    sidebar: sidebarSlice.reducer,
    ingest: ingestSlice.reducer,
    catalog: catalogSlice.reducer,
    catalogOrder: catalogOrderSlice.reducer,
    orders: ordersSlice.reducer,
    order: orderSlice.reducer,
    organizations: organizationsSlice.reducer,
    users: usersSlice.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [workflowApi.reducerPath]: workflowApi.reducer,
    repositoryItems: repositoryItemsSlice.reducer,
    repositoryItem: repositoryItemSlice.reducer,
    [castlabsRepositoryApi.reducerPath]: castlabsRepositoryApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(commonApi.middleware)
      .concat(workflowApi.middleware)
      .concat(castlabsRepositoryApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
