import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

export default function Layout({ children, expanded }: { children: ReactNode; expanded: boolean }) {
  const [visibleHeight, setVisibleHeight] = useState<number>(0);

  const topRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const contentBottomRef = useRef<HTMLDivElement | null>(null);

  const contentChild = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === Content
  );
  const rightSideChild = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === RightSide
  );

  const updateVisibleHeight = () => {
    const maxHeight = () => {
      const MARGIN_BOTTOM = 35;

      const viewPortHeight = window.innerHeight;
      const contentHeight = contentRef.current?.getBoundingClientRect().height ?? 0;
      const topPosition = topRef.current?.getBoundingClientRect().top ?? 0;
      const contentBottomPosition = contentBottomRef.current?.getBoundingClientRect().top ?? 0;

      const isContentShorterThanViewport = contentHeight < viewPortHeight;

      if (isContentShorterThanViewport) {
        return viewPortHeight - topPosition - MARGIN_BOTTOM;
      }

      if (topPosition > 0) {
        return viewPortHeight - topPosition;
      }

      const isContentBottomVisible = contentBottomPosition < viewPortHeight;

      if (isContentBottomVisible) {
        return contentBottomPosition;
      }

      return viewPortHeight;
    };
    setVisibleHeight(Math.max(0, maxHeight()));
  };

  useEffect(() => {
    updateVisibleHeight();
    window.addEventListener('scroll', updateVisibleHeight);
    window.addEventListener('resize', updateVisibleHeight);

    return () => {
      window.removeEventListener('scroll', updateVisibleHeight);
      window.removeEventListener('resize', updateVisibleHeight);
    };
  }, [children]);

  return (
    <div className="container mx-auto flex relative mb-8 bg-gray-25 border-2 border-gray-200 rounded-xl [&>div]:transition-all [&>div]:duration-300 ">
      <div ref={topRef} className="absolute w-full h-0 bg-primary"></div>
      <div
        ref={contentRef}
        className={clsx('border-gray-200', {
          'w-full': expanded,
          'w-8/12 border-r-2': !expanded
        })}>
        {contentChild &&
          React.cloneElement(contentChild as ReactElement<LayoutChildProps>, {
            visibleHeight
          })}
        <div ref={contentBottomRef} className="w-full h-0 bg-gray"></div>
      </div>
      <div
        className={clsx('', {
          'w-0': expanded,
          'w-4/12': !expanded
        })}>
        {rightSideChild &&
          React.cloneElement(rightSideChild as ReactElement<LayoutChildProps>, {
            visibleHeight
          })}
      </div>
    </div>
  );
}

interface LayoutChildProps {
  visibleHeight?: number;
  children: ReactNode;
}

const Content: React.FC<LayoutChildProps> = ({ children }) => {
  return <div>{children}</div>;
};

const RightSide: React.FC<LayoutChildProps> = ({ children, visibleHeight }) => {
  return (
    <div className="sticky top-0 right-0 h-max rounded-tr-xl rounded-br-xl overflow-hidden">
      <div style={{ height: `${visibleHeight}px` }}>{children}</div>
    </div>
  );
};

Layout.Content = Content;
Layout.RightSide = RightSide;
