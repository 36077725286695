import Modal from '../ui/Modal';
import Button from '../ui/Button';
import ShareIcon from '../../assets/icons/share-2.svg?react';
import { Asset } from '../../features/repository/types';
import TreeViewNodeAssetTemplate from './TreeViewNodeAssetTemplate';
import React from 'react';
import { mapTreeViewNodeAssetPropsFromAsset } from '../../pages/repository/QualityControlPage';

function VideoQcConflictModal({
  onCancel,
  onConfirm,
  acceptedSidecarFiles
}: {
  onConfirm: () => void;
  onCancel: () => void;
  acceptedSidecarFiles?: Asset[];
}) {
  return (
    <Modal
      visible={true}
      setVisible={onCancel}
      className={'max-w-[700px] min-w-[600px]'}
      title="Note">
      <div className="p-4">
        {/* Content */}
        <div className="text-gray-600 text-sm ">
          Another approved video asset already exists. Would you like to set it to be deleted and
          the following sidecar files to an un-reviewed state?
        </div>
        {acceptedSidecarFiles && (
          <div>
            {acceptedSidecarFiles.map((asset, idx) => (
              <div
                key={`asset-${idx}`}
                className={'border rounded-xl p-4 w-full relative border-gray-200 mt-4'}>
                <TreeViewNodeAssetTemplate
                  asset={mapTreeViewNodeAssetPropsFromAsset(asset).value}
                  showBadge={false}
                />
              </div>
            ))}
          </div>
        )}
        {/* Footer */}
        <div className="flex justify-end mt-8 gap-2">
          <Button rounded onClick={onCancel}>
            Cancel
          </Button>
          <Button rounded variant="primary" className="font-semibold text-md" onClick={onConfirm}>
            <ShareIcon className="h-5 w-5 stroke-white" />
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default VideoQcConflictModal;
