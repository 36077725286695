import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

// -- External components & icons --
import HomeIcon from '../../assets/icons/home.svg?react';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';
import TreeView, { findTreeViewParentNode, TreeViewNode } from '../../components/ui/TreeView';
import Skeleton from '../../components/ui/Skeleton';
import { AbovePlayer } from '../../components/asset/AbovePlayer';
import TreeViewNodeAssetTemplate, {
  TreeViewNodeAssetTemplateProps
} from '../../components/repository-quality-control/TreeViewNodeAssetTemplate';
import AssetQualityControlSteps, {
  AssetQualityControlStep
} from '../../components/repository-quality-control/AssetQualityControlSteps';
import AssessmentForm from '../../components/repository-quality-control/AssessmentForm';
import StepValidationForm from '../../components/repository-quality-control/StepValidationFormItem';

// -- Redux & store --
import { addAssetQcReport, getRepositoryItem } from '../../features/repository/repositoryApi';
import {
  useGeneratePreviewTokenMutation,
  useGetProcessMutation,
  usePreviewGenerateMutation
} from '../../features/repository/castlabsRepositoryApi';
import { setRepositoryItem } from '../../features/repository/repositoryItemSlice';

// -- Types & utils --
import {
  Asset,
  AssetQCReportInput,
  QCReportResolution,
  RepositoryItemDetails
} from '../../features/repository/types';
import VideoQcConflictModal from '../../components/repository-quality-control/VideoQcConflictModal';
import {
  extractFailedOptionValues,
  QCAutoCheck,
  QCCheck,
  qcChecks
} from '../../features/repository/qualityControlChecks';
import { RootState } from '../../app/store';
import { getOptions } from '../../common/utils';

interface PreviewTokens {
  dashManifestUrl: string;
  drmTodayToken: string;
}

const breadcrumbItems: BreadcrumbsItem[] = [
  {
    link: '/',
    icon: <HomeIcon />
  },
  {
    title: 'Repository',
    link: '/repository'
  }
];

/**
 * Sorts TreeView nodes first by type, then by language.
 */
function sortByLanguageThenFiletype(
  a: TreeViewNode<TreeViewNodeAssetTemplateProps, string>,
  b: TreeViewNode<TreeViewNodeAssetTemplateProps, string>
) {
  // Compare types
  if (a.value.type < b.value.type) return -1;
  if (a.value.type > b.value.type) return 1;

  // If types are the same, compare languages
  if (a.value.language < b.value.language) return -1;
  if (a.value.language > b.value.language) return 1;
  return 0;
}

/**
 * Maps an Asset to the TreeViewNode props required for rendering in TreeView.
 */
export function mapTreeViewNodeAssetPropsFromAsset(
  asset: Asset
): TreeViewNode<TreeViewNodeAssetTemplateProps, string> {
  return {
    id: asset._id,
    value: {
      filename: asset.location.split('/').pop() ?? '',
      location: asset.location,
      language: asset.language,
      message: asset.comment,
      status: asset.status,
      type: asset.type
    }
  };
}

/**
 * Determines if an asset can be approved based on provided QC steps and user-selected answers.
 * Returns `true` if all steps are completed, no bad options are selected, otherwise `false`.
 */
function canApproveAsset(
  steps: AssetQualityControlStep[],
  assetQcReportAnswers: Record<string, string[] | null | undefined>
): boolean {
  if (!steps.length) return false;

  const passedSteps = steps.filter((step) => {
    const answers = assetQcReportAnswers[step.id];
    if (!answers || answers.length === 0) return false;

    const hasFailedOption = answers.some((option) => step.form.badOptionKeys.includes(option));
    return !hasFailedOption;
  });

  return passedSteps.length === steps.length;
}

/**
 * The main Quality Control page component.
 */
export default function QualityControlPage() {
  const { titleId } = useParams();
  const dispatch = useDispatch();

  // -- Submitted QC reports --
  const [qcReports, setQcReports] = useState<
    Record<string, { comment: string; formValues: Record<string, string[] | null | undefined> }>
  >(Object.create(null));
  // -- Loading & UI state --
  const [isLoading, setIsLoading] = useState(true);

  // -- Tree data & preview tokens --
  const [treeData, setTreeData] = useState<TreeViewNode<TreeViewNodeAssetTemplateProps, string>[]>(
    []
  );
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [previewTokens, setPreviewTokens] = useState<PreviewTokens | null>(null);

  const { repositoryItem } = useSelector((state: RootState) => state.repositoryItem);
  const acceptedSidecarFiles = repositoryItem?.assets.filter(
    (asset) => asset.type !== 'VIDEO' && asset.status === 'ACCEPTED'
  );
  const treeviewParent = findTreeViewParentNode(treeData, selectedNodeId);

  const currentVideoAsset = repositoryItem?.assets.find(
    (asset) => asset._id === selectedNodeId || asset._id === treeviewParent?.id
  );

  const currentAsset = repositoryItem?.assets.find((asset) => asset._id === selectedNodeId);
  //-- BEGIN Steps--
  function extractOptions(
    options: { value: string; answer: string }[] | undefined
  ): { [key: string]: string } | undefined {
    const result: Record<string, string> = {};

    options?.forEach((option) => {
      result[option.value] = option.answer;
    });

    return result;
  }

  function extractAutoChecks(auto: QCAutoCheck[]): AssetQualityControlStep[] {
    const autoChecks: Record<string, string[] | null | undefined> = {};
    const items = auto.map((step) => {
      autoChecks[step.id] = step.check(currentVideoAsset, currentAsset);
      return {
        id: step.id,
        title: step.question,
        type: 'auto',
        form: {
          label: step.question,
          options: extractOptions(step.options),
          badOptionKeys: extractFailedOptionValues(step)
        }
      } as AssetQualityControlStep;
    });
    setAssetQcReport((prev) => ({
      ...prev,
      ...autoChecks
    }));
    return items;
  }
  function extractManual(manual: QCCheck[]) {
    return manual.map(
      (step) =>
        ({
          id: step.id,
          title: step.question,
          type: 'manual',
          form: {
            label: step.question,
            options: extractOptions(step.options),
            badOptionKeys: extractFailedOptionValues(step)
          }
        }) as AssetQualityControlStep
    );
  }

  const extractStepsFromQcChecks = ({
    auto,
    manual
  }: {
    auto: QCAutoCheck[];
    manual: QCCheck[];
  }) => [...extractAutoChecks(auto), ...extractManual(manual)];
  //-- END Steps--

  const [steps, setSteps] = useState<AssetQualityControlStep[]>([]);
  const [selectedStepId, setSelectedStepId] = useState<string | undefined>();
  const currentStep = steps?.find((step) => step.id === selectedStepId);

  // -- QC answers data --
  const [assetComment, setAssetComment] = useState<string>('');
  const [assetQcReport, setAssetQcReport] = useState<Record<string, string[] | null | undefined>>(
    {}
  );

  // -- QC conflict modal --
  const [isConflictModalOpen, setIsConflictModalOpen] = useState(false);

  // -- API mutations --
  const [previewGenerate] = usePreviewGenerateMutation();
  const [generatePreviewToken] = useGeneratePreviewTokenMutation();
  const [processQuery] = useGetProcessMutation();

  /**
   * Fetches the repository item for the provided `titleId` and updates component state.
   */
  async function fetchRepositoryItem(id: string) {
    try {
      setIsLoading(true);
      const repoItem = await getRepositoryItem(id);

      dispatch(setRepositoryItem(repoItem));

      // Prepare and load UI data
      prepareTreeViewData(repoItem.assets);
      setIsLoading(false);
      await prepareVideoPlayer(repoItem);
    } catch (error) {
      console.error('Error fetching repository item:', error);
      setIsLoading(false);
    }
  }

  /**
   * Maps array of assets to the tree data format required by the TreeView component,
   * placing sidecar files under their VIDEO file if its status is ACCEPTED.
   */
  function prepareTreeViewData(assets: Asset[]) {
    const sidecarFiles: TreeViewNode<TreeViewNodeAssetTemplateProps, string>[] = assets
      .filter((file) => file.type !== 'VIDEO')
      .map(mapTreeViewNodeAssetPropsFromAsset)
      .sort(sortByLanguageThenFiletype);

    const videoNodes: TreeViewNode<TreeViewNodeAssetTemplateProps, string>[] = assets
      .filter((file) => file.type === 'VIDEO')
      .map((asset) => {
        const node = mapTreeViewNodeAssetPropsFromAsset(asset);
        node.children = asset.status === 'ACCEPTED' ? sidecarFiles : [];
        return node;
      });

    setTreeData(videoNodes);
  }

  /**
   * Generates a preview of the video in CastLabs, polls for process success,
   * and once complete, fetches a preview token for the player.
   */
  async function prepareVideoPlayer(repoItem: RepositoryItemDetails) {
    const { data: generatePreviewResponse } = await previewGenerate({
      folder_id: repoItem.folderLocation
    });
    if (!generatePreviewResponse) return;

    const process = await fetchSuccessProcess(generatePreviewResponse.id);
    if (!process) return;

    try {
      const previewGenerateData = JSON.parse(process.data);
      const tokenResponse = await generatePreviewToken({ asset_id: previewGenerateData.asset_id });
      if (tokenResponse.data) {
        setPreviewTokens({
          dashManifestUrl: tokenResponse.data.dash_url,
          drmTodayToken: tokenResponse.data.drmtoday_token
        });
      }
    } catch (error) {
      console.error('Error generating preview token', error);
    }
  }

  /**
   * Polls for a process to reach 'SUCCESS' state and returns the process data.
   * Retries up to `maxRetries` times with a delay of `retryDelay` between attempts.
   */
  async function fetchSuccessProcess(
    id: string,
    maxRetries: number = 100,
    retryDelay: number = 5000
  ) {
    async function sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      try {
        const { data: process } = await processQuery({ id });
        if (!process) {
          console.warn(`No process data returned on attempt ${attempt}.`);
        } else {
          switch (process.state) {
            case 'SUCCESS':
              return process;
            case 'IN_PROGRESS':
              if (attempt < maxRetries) {
                await sleep(retryDelay);
              }
              break;
            case 'FAILED':
              return null;
            default:
              console.warn(`Unknown process state: ${process.state}`);
          }
        }
      } catch (error) {
        console.error(`processQuery failed on attempt ${attempt}:`, error);
        throw error;
      }
    }
    throw new Error(`Process did not reach 'SUCCESS' after ${maxRetries} attempts.`);
  }

  /**
   * Navigate between steps in the QC steps array (forward or backward).
   */
  function handleStepNavigation(direction: 'next' | 'prev') {
    const currentIndex = steps.findIndex((step) => step.id === selectedStepId);
    if (currentIndex === -1) {
      // If no step is selected, select the first step
      return setSelectedStepId(steps[0]?.id);
    }

    const nextIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
    if (nextIndex >= 0 && nextIndex < steps.length) {
      setSelectedStepId(steps[nextIndex].id);
    }
  }

  /**
   * Called when user completes or submits the QC report (e.g., clicking 'Submit' on the form).
   * For now, this is a placeholder to show where you'd handle final data submission.
   */
  async function handleQcReportSubmission(
    action: 'ACCEPTED' | 'TO_CONFORM' | 'TO_DELETE',
    comment: string,
    force: boolean = false
  ) {
    if (!selectedNodeId) return;

    if (action === 'TO_DELETE' && acceptedSidecarFiles && acceptedSidecarFiles.length > 0) {
      setIsConflictModalOpen(true);
      return;
    }

    // Submit QC data somewhere...
    console.log('QC Report submitted:', assetQcReport);

    setQcReports((prev) => ({
      ...prev,
      [selectedNodeId]: {
        comment,
        formValues: assetQcReport
      }
    }));

    // Submit QC Report

    function extractAssetQcReport(
      assetQcReport: Record<string, string[] | null | undefined>
    ): AssetQCReportInput {
      function extractVerdict(
        badOptionKeys: string[],
        answer: string[] | null | undefined
      ): QCReportResolution {
        if (!answer) return 'WARNING';

        if (answer.some((option) => badOptionKeys.includes(option))) {
          return 'FAILED';
        }

        return 'PASSED';
      }
      function extractAnswer(
        options: { [key: string]: string },
        answer: string[] | null | undefined
      ): string {
        if (!answer || answer.length === 0) return '';

        return getOptions(options)
          .filter((option) => answer.includes(option.value))
          .map((option) => option.label)
          .join(', ');
      }
      const requestBody = {
        questions: Object.values(steps).map((step) => ({
          question: step.title,
          answer: extractAnswer(step.form.options, assetQcReport[step.id]),
          verdict: extractVerdict(step.form.badOptionKeys, assetQcReport[step.id])
        })),
        generalObservation: comment,
        resolution: action
      };
      console.log('graphql mutation:', requestBody);

      return requestBody;
    }

    try {
      extractAssetQcReport(assetQcReport);
      /* const qcReportSubmitResponse = await addAssetQcReport(
        selectedNode,
        extractAssetQcReport(assetQcReport),
        force
      );*/
    } catch (e) {
      console.error('Error submitting QC report:', e);
    }

    setAssetQcReport({});
    setAssetComment('');

    /*if (!qcReportSubmitResponse)*/ {
      // If is Video Node, set first child as selected
      const videoNode = treeData.find((node) => node.id === selectedNodeId);
      if (videoNode) {
        const childNode = videoNode.children?.[0];
        if (childNode) {
          setSelectedNodeId(childNode.id);
        }
      } else {
        // If is Sidecar Node, set next sibling as selected

        if (treeviewParent) {
          const currentIndex = treeviewParent.children?.findIndex(
            (child) => child.id === selectedNodeId
          );
          if (currentIndex !== undefined && currentIndex !== -1) {
            const nextSibling = treeviewParent.children?.[currentIndex + 1];
            if (nextSibling?.id) {
              setSelectedNodeId(nextSibling.id);
            }
          }
        }
      }
    }
  }

  // Fetch data when `titleId` changes
  useEffect(() => {
    if (titleId) fetchRepositoryItem(titleId);
  }, [titleId]);

  // Select the first node by default
  useEffect(() => {
    if (!treeData.length) return;
    // When assets tree is rendered, select the first node accepted video by default
    if (!selectedNodeId) {
      const getDefaultNodeId = () => {
        // Search for a Video by this order: ACCEPTED, TO_CONFORM, NEW

        const videoTypes = ['ACCEPTED', 'TO_CONFORM', 'NEW'];
        // sort the nodes by status
        const sortedNodes = treeData.sort(
          (a, b) =>
            videoTypes.indexOf(a.value.status ?? '') - videoTypes.indexOf(b.value.status ?? '')
        );

        // get the first node
        return sortedNodes[0].id;
      };
      setSelectedNodeId(getDefaultNodeId());
    }
  }, [treeData]);

  // Update steps when selecting a new node
  useEffect(() => {
    if (!selectedNodeId) return;
    function getChecksForAssetType(type: string | undefined): {
      auto: QCAutoCheck[];
      manual: QCCheck[];
    } {
      switch (type) {
        case 'VIDEO':
          return qcChecks.video;
        case 'AUDIO':
          return qcChecks.audio;
        case 'SUBTITLE':
          return qcChecks.subtitle;
        case 'CLOSED_CAPTION':
          return qcChecks.closedcaption;
        default:
          console.error('Unknown asset type:', type);
          return { auto: [], manual: [] };
      }
    }
    const checks = getChecksForAssetType(
      repositoryItem?.assets.find((asset) => asset._id === selectedNodeId)?.type
    );

    const _steps = extractStepsFromQcChecks(checks);
    setSteps(_steps);
    setSelectedStepId(_steps[0].id);

    // Reset QC report when selecting a new node
    if (qcReports[selectedNodeId]) {
      setAssetQcReport(qcReports[selectedNodeId].formValues);
      setAssetComment(qcReports[selectedNodeId].comment);
    }
  }, [selectedNodeId]);

  // Derived state: can the current asset be approved based on answered steps?
  const canApprove = canApproveAsset(steps, assetQcReport);

  // Only navigate if the step is the next uncompleted or is already completed
  function handleSetActiveStep(stepId: string) {
    // Get next step without answer
    const nextStepWithoutAnswer = steps.find(
      (step) => !(assetQcReport[step.id] && assetQcReport[step.id]?.length !== 0)
    );

    if (
      (nextStepWithoutAnswer && nextStepWithoutAnswer.id === stepId) ||
      (assetQcReport[stepId] && assetQcReport[stepId]?.length > 0)
    ) {
      setSelectedStepId(stepId);
    }
  }

  async function handleVideoQcConflictConfirm() {
    // TODO
    if (!selectedNodeId) return;
    setIsConflictModalOpen(false);

    // Submit QC data somewhere...
    console.log('QC Report submitted:', assetQcReport);

    setQcReports((prev) => ({
      ...prev,
      [selectedNodeId]: {
        comment: assetComment,
        formValues: assetQcReport
      }
    }));

    // Submit QC Report

    function extractAssetQcReport(
      assetQcReport: Record<string, string[] | null | undefined>
    ): AssetQCReportInput {
      function extractVerdict(
        badOptionKeys: string[],
        answer: string[] | null | undefined
      ): QCReportResolution {
        if (!answer) return 'WARNING';

        if (answer.some((option) => badOptionKeys.includes(option))) {
          return 'FAILED';
        }

        return 'PASSED';
      }
      function extractAnswer(
        options: { [key: string]: string },
        answer: string[] | null | undefined
      ): string {
        if (!answer || answer.length === 0) return '';

        return getOptions(options)
          .filter((option) => answer.includes(option.value))
          .map((option) => option.label)
          .join(', ');
      }
      const requestBody = {
        questions: Object.values(steps).map((step) => ({
          question: step.title,
          answer: extractAnswer(step.form.options, assetQcReport[step.id]),
          verdict: extractVerdict(step.form.badOptionKeys, assetQcReport[step.id])
        })),
        generalObservation: assetComment,
        resolution: 'TO_DELETE'
      };
      console.log('graphql mutation:', requestBody);

      return requestBody;
    }

    try {
      extractAssetQcReport(assetQcReport);
      /*const qcReportSubmitResponse = await addAssetQcReport(
        selectedNodeId,
        extractAssetQcReport(assetQcReport),
        true
      );*/
    } catch (e) {
      console.error('Error submitting QC report:', e);
    }

    setAssetQcReport({});
    setAssetComment('');
  }

  return (
    <>
      <Helmet>
        <title>Above Media | Repository</title>
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />

      <div className="container mx-auto mb-8 bg-white border-2 border-gray-200 rounded-xl">
        <div className="p-5 border-b border-gray-100">
          <div className="font-semibold text-lg text-gray-900">Quality Control Page</div>
        </div>

        {isLoading && <Skeleton />}

        {!isLoading && (
          <div className="relative mx-12">
            {/* TreeView (left sidebar) */}
            <TreeView
              data={treeData}
              selected={selectedNodeId}
              setSelected={setSelectedNodeId}
              render={(value) => <TreeViewNodeAssetTemplate asset={value} />}
              onSelectBehaviour={'collapse'}
            />
            {/*Main content area*/}
            <div className="grid grid-cols-7 gap-8 mt-12">
              {/* QC Steps*/}
              <div className="col-span-2 flex flex-row ml-8 relative">
                <AssetQualityControlSteps
                  className="sticky pt-8 top-0"
                  steps={steps}
                  selected={selectedStepId}
                  setSelected={handleSetActiveStep}
                  qcReport={assetQcReport}
                />
              </div>
              {/* Player & QC Forms*/}
              <div
                className={clsx({
                  'col-span-5': selectedNodeId,
                  'col-span-7': !selectedNodeId
                })}>
                <div className="p-8 bg-gray-50 rounded-2xl">
                  <AbovePlayer
                    className="mb-8"
                    dashManifestUrl={previewTokens?.dashManifestUrl ?? ''}
                    drmTodayToken={previewTokens?.drmTodayToken ?? ''}
                    rounded
                  />

                  {currentStep && (
                    <StepValidationForm
                      question={currentStep.form}
                      disabled={currentStep.type === 'auto'}
                      value={assetQcReport[currentStep.id]}
                      setValue={(value) => {
                        setAssetQcReport((prev) => ({ ...prev, [currentStep.id]: value }));
                      }}
                      onNavigate={handleStepNavigation}
                      canNavigateNext={selectedStepId !== steps[steps.length - 1]?.id}
                      canNavigatePrev={selectedStepId !== steps[0].id}
                    />
                  )}
                </div>

                {/*Final QC approval form*/}
                <AssessmentForm
                  comment={assetComment}
                  setComment={setAssetComment}
                  onSubmit={handleQcReportSubmission}
                  canApprove={canApprove}
                />

                {isConflictModalOpen && (
                  <VideoQcConflictModal
                    onCancel={() => setIsConflictModalOpen(false)}
                    onConfirm={handleVideoQcConflictConfirm}
                    acceptedSidecarFiles={acceptedSidecarFiles}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
